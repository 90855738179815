import { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment';
import ApiGet from '../Hooks/ApiGet';
import MatchesSmall from '../Components/Profile/Matches/MatchesSmall';
import logoWPAI from '../assets/images/logo192.png';
import TitreH5 from '../Components/Titres/TitreH5';
import TrackerConfig from '../Components/Tracker/TrackerConfig';
import GetSeason from '../Hooks/getSeason';

const profileExpirationTimeRefresh = 20;
const matchesRefreshTime = 5;
const profileExpirationTime = profileExpirationTimeRefresh * 60 * 1000; // profileExpirationTimeRefresh minutes in milliseconds 
const matchExpirationTime = matchesRefreshTime * 60 * 1000; // profileExpirationTimeRefresh minutes in milliseconds 
const dev = false;

function PlayerProfile(props) {
    const [profileIsSet, setProfileIsSet] = useState(false);
    const [refreshTimestamp, setRefreshTimestamp] = useState();
    const [refreshTimeDiff, setRefreshTimeDiff] = useState();
    const [PlayerDatas, setPlayerDatas] = ApiGet();
    const [PlayerStats, setPlayerStats] = ApiGet();
    const [RankedStats, setRankedStats] = ApiGet();
    const [playerId, setPlayerId] = useState();
    const [matches, setMatches] = useState();
    const [TopProfile, setTopProfile] = useState([{name: '', title: '', alertype:"warning"}]);
    const [time, setTime] = useState(Date.now()); // État pour forcer le refresh
    const [currentSeason, setCurrentSeason] = GetSeason();

    // Ne pas oublier d'ajouter tout nouveau state dans la function de reset
    let profilesPUBG = JSON.parse(localStorage.getItem("Profiles-ureg-pubg")) || [];
    
    const history = useHistory();

    const handleRefresh = () => {
        const queryParams = new URLSearchParams(window.location.search);
        
        if (queryParams.get("overlay") === "0") {
            document.body.classList.add("no-overlay");
        } else if (queryParams.get("overlay") === "1") {
            document.body.classList.remove("no-overlay");
        }
        if (queryParams.get("profiles") === "0") {
            document.body.classList.add("no-profiles");
        } else if (queryParams.get("profiles") === "1") {
            document.body.classList.remove("no-profiles");
        }
        if (queryParams.get("width") === "1") {
            document.body.classList.add("no-width");
        } else if (queryParams.get("width") === "0") {
            document.body.classList.remove("no-width");
        }
        if (queryParams.get("rank") === "0") {
            document.body.classList.add("no-rank");
        } else if (queryParams.get("rank") === "1") {
            document.body.classList.remove("no-rank");
        }
        if (queryParams.get("updated") === "0") {
            document.body.classList.add("no-updated");
        } else if (queryParams.get("updated") === "1") {
            document.body.classList.remove("no-updated");
        }
        if (queryParams.get("cfg") === "0") {
            document.body.classList.remove("has-cfg");
        } else if (queryParams.get("cfg") === "1") {
            document.body.classList.add("has-cfg");
        }
        if (queryParams.get("games") === "4") {
            document.body.classList.add("games-4");
            document.body.classList.remove("games-12");
            document.body.classList.remove("games-10");
        } else if (queryParams.get("games") === "8") {
            document.body.classList.remove("games-4");
            document.body.classList.remove("games-12");
            document.body.classList.remove("games-10");
        } else if (queryParams.get("games") === "12") {
            document.body.classList.add("games-12");
            document.body.classList.remove("games-4");
            document.body.classList.remove("games-10");
        } else if (queryParams.get("games") === "10") {
            document.body.classList.add("games-10");
            document.body.classList.remove("games-4");
            document.body.classList.remove("games-12");
        }
    };

    useEffect(() => {
        document.body.className = 'tracker'
        document.title = `${props.match.params.nom} - WPAI PUBG Profile`;
        handleRefresh()
        setCurrentSeason('https://wpai-season-default-rtdb.europe-west1.firebasedatabase.app/currentSeason.json', true)
    }, [props.refreshedPage, time])

    useEffect(() => {
        const interval = setInterval(() => {
          setTime(Date.now());
        }, matchExpirationTime);
    
        return () => clearInterval(interval); // Nettoie l'intervalle quand le composant se démonte
      }, []);

    useEffect(() => {
        if(currentSeason) {
            // console.log(currentSeason)
        }
    }, [currentSeason])

    useEffect(() => {
        if(PlayerDatas && PlayerDatas.err && PlayerDatas.msg && PlayerDatas.type) {
            props.toAlertHandler(PlayerDatas.err, PlayerDatas.msg, PlayerDatas.type);
            history.push('/');
        } 
        
    },[PlayerDatas])


    useEffect(() => {
        // On reset la valeur d'actualisation de la page
        if(props.refreshedPage){
            document.getElementById("logoTop").scrollIntoView();
            props.refreshPageHandler()
            setProfileIsSet(false)
            // props.refresh()
        }
    }, [props.refreshedPage])

    useEffect(() => {

        // On check le storage et on le supprime après 20min
        checkAndRemoveExpiredProfiles()

        // Infos du joueur
        if(props.match.params.nom) {
            let indexProfile = false;
            if(checkPlayerStorage(props.match.params.nom)) {
                    profilesPUBG.forEach((profile, index) => {
                        if(props.match.params.nom === profile.name) {
                            indexProfile = index;
                        }
                    });
                    
                // setPlayerId(profilesPUBG[indexProfile].data.relationships.player.data.id);
                setPlayerDatas(profilesPUBG[indexProfile], false)
            } else {
                setPlayerDatas(`https://api.pubg.com/shards/steam/players?filter[playerNames]=${props.match.params.nom}`, true)
            }
        } 
    }, [props.refreshedPage])

    useEffect(() => {
        // Set du player id
        if(PlayerDatas && PlayerDatas.data) {
            if(PlayerDatas.data[0]) {
                setPlayerId(PlayerDatas.data[0].id);
            } else {
                setPlayerId(PlayerDatas.data.relationships.player.data.id);
            }
        }
    }, [PlayerDatas])

    useEffect(() => {
        // Infos du joueur lifetime
        if(playerId && !props.refreshedPage) {
            if(checkPlayerStorage(props.match.params.nom)) {
                addDatasToStates(playerId)
                
                console.log('Added from storage')
                setProfileIsSet(true)
            } else {
                setPlayerStats(`https://api.pubg.com/shards/steam/players/${playerId}/seasons/lifetime`, true)
                
                console.log('Added from api')
            }
        }        
    }, [playerId, props.refreshedPage])

    useEffect(() => {
        if(playerId && currentSeason) {
            setRankedStats(`https://api.pubg.com/shards/steam/players/${playerId}/seasons/${currentSeason}/ranked`, true)
        }
    }, [PlayerStats, props.refreshedPage, currentSeason])

    useEffect(() => {
        if(RankedStats && RankedStats.data) {
            // console.log('RankedStats')
            // console.log(RankedStats.data.attributes.rankedGameModeStats["squad-fpp"]);
        }
    }, [RankedStats])

    useEffect(() => {
        if(PlayerStats) {
            // console.log(PlayerStats)
            if(!profileIsSet) {
                addProfile(PlayerStats)
            }
        }
    }, [PlayerStats])


    useEffect(() => {
        if(PlayerStats) {
            if(PlayerStats && PlayerStats !== undefined) {
                setMatches(PlayerStats.matches);
            }
        } else {
            setMatches([]);
        }        

    }, [PlayerStats])

    useEffect(() => {
        if(refreshTimestamp) {
            calcTimeDiff()

            setInterval(() => {    
                calcTimeDiff()
            }, 30000);
        }
    }, [refreshTimestamp])

    useEffect(() => {
        if(playerId) {
            document.getElementById("logoTop").scrollIntoView();
            props.refreshPageHandler(true)
            props.refresh()
        }
    }, [props.match.params.nom])

    const addTopProfile = (newProfile) => {
        setTopProfile([newProfile]); // Ajoute le profil
    };

    function copyTrackerLink() {
        props.toAlertHandler(200,'Tracker copied', 'info')
        navigator.clipboard.writeText(document.getElementById("trackerLink").value).then(function() {}, function(err) {console.error('Erreur lors de la copie dans le presse-papier : ', err);});
      }

    function addProfile(profile) {
        const currentTimestamp = Date.now();
        if(PlayerStats && PlayerStats.data) {
            profile.timestamp = currentTimestamp;
            profile.name = props.match.params.nom;
            if(PlayerDatas && PlayerDatas !== undefined) {
                profile.matches = PlayerDatas.data[0].relationships.matches.data;
            }

            profilesPUBG.push(profile);
            localStorage.setItem("Profiles-ureg-pubg", JSON.stringify(profilesPUBG));

            setRefreshTimestamp(currentTimestamp)
        }
        setProfileIsSet(true)
    }

    function checkAndRemoveExpiredProfiles() {
        let StorageProfiles = JSON.parse(localStorage.getItem("Profiles-ureg-pubg")) || [];

        StorageProfiles.forEach((profile, index) => {

            if (moment().diff(profile.timestamp, 'minutes') >= profileExpirationTimeRefresh) {
                StorageProfiles.splice(index, 1);
            }
        });
        localStorage.setItem("Profiles-ureg-pubg", JSON.stringify(StorageProfiles));
    }

    function checkPlayerStorage(playerId) {
        let playerData = null;
                
        for (let i = 0; i < profilesPUBG.length; i++) {
            if (playerId === profilesPUBG[i].name) {
                playerData = true;
                setRefreshTimestamp(profilesPUBG[i].timestamp)
            }
        }
        

        if (playerData === null) {
          // console.log(`Le stockage pour le joueur ${playerId} n'est pas défini`);
          return false;
        }
        // console.log(`Le stockage pour le joueur ${playerId} est défini`);
        return true;
      }

      function addDatasToStates(playerId) {
        let currentProfile = null;
        profilesPUBG.forEach((profile, index) => {
            if (playerId === profile.data.relationships.player.data.id) {
                    currentProfile = profilesPUBG.splice(index, 1);
            }
        });
        
        if(!PlayerStats) {
            setPlayerStats(currentProfile[0], false)
        }
      }

        function calcTimeDiff() {
            if(refreshTimestamp) {
                const regTimestamp = moment().diff(refreshTimestamp, 'minutes');  
                let timeDiff = profileExpirationTimeRefresh - regTimestamp; 
                
                if(timeDiff <= 0) {
                    timeDiff = 0;
                }

                setRefreshTimeDiff(timeDiff);
                checkAndRemoveExpiredProfiles();
            }
        }
    

    return (
        <> 
            <div id="logoTop" className='logo-profil'>
                <NavLink to="/">
                    <img src={logoWPAI} alt="Logo WPAI" />
                </NavLink>
                <NavLink to={`/profile/${props.match.params.nom}`} className='text-decoration-none ms-2'>
                    <small >⇽ Back to <b className='text-primary text-uppercase'>{props.match.params.nom}</b> profile</small> 
                </NavLink>
                {/* <button onClick={props.refreshPageHandler}>refresh</button> */}
            </div>
            <div className='tracker-outer'>
                <div className='trackerMain mx-0 px-1 py-1'>
                {!props.refreshedPage ? 
                <>
                {(PlayerStats) ? 
                    <>
                        <div className='trackerContainer overlay no-backdrop'>
                            <p className="profileTitleSmall d-flex align-items-center justify-content-between mt-0 gap-1 pe-2">
                                <span className="d-flex align-items-center mt-0">
                                    <span style={{marginRight: '3px', transform: 'translateY(-2px)'}}>
                                    {props.match.params.nom && ((t) => t.length > 14 ? t.slice(0, 14) + "." : t)(props.match.params.nom  || "")}
                                    </span>
                                    {(TopProfile[0].name != "") &&
                                        <small className='text-white text-lowercase'>is <small className={`text-uppercase text-${TopProfile[0].alertype}`}>{TopProfile[0].name}</small></small>
                                    }
                                </span>
                                {(RankedStats && Object.keys(RankedStats.data.attributes.rankedGameModeStats).length > 0) && 
                                    <>
                                        <small className='trackerRank text-white'>{(t => t.length > 4 ? t.slice(0, 4) + "" : t)(RankedStats.data.attributes.rankedGameModeStats["squad-fpp"].currentTier.tier || "")} {RankedStats.data.attributes.rankedGameModeStats["squad-fpp"].currentTier.subTier}</small>
                                    </>
                                }
                            </p>
                            {(PlayerStats && (matches && matches.length > 0)) &&
                                <>
                                    <div id="matchesDiv" className='mb-0 p-0 matchesDivSmall row'>
                                        <div className='rowMatches row-grided d-flex flex-wrap'>
                                            <MatchesSmall refreshTime={time} refreshPageHandler={props.refreshPageHandler} refreshTimeDiff={refreshTimeDiff} addTopProfile={addTopProfile} nom={props.match.params.nom} refresh={props.refresh} matchesId={matches} playerId={playerId} />
                                        </div>
                                    </div>

                                </>
                            }  
                            {matches && matches.length === 0 && 
                            <div className='overlay-light p-2 mt-4 mb-5'>
                                <TitreH5 className="w-100 text-center">{props.match.params.nom} has no game played in the last 14 days</TitreH5>
                                <p className="w-100 text-center"><small>Due to the lack of matches datas, nothing is appearing here and there is no "Most Played Map"</small></p>
                            </div>
                            }
                            <div className='d-flex justify-content-between align-items-baseline pe-2'>
                                <small className='copyrightTracker'>Made with <img className='smallLogo' src={logoWPAI} alt="Logo WPAI" />WPAI PUBG</small>
                                <small className='fs-xs opacity-50 trackerUpdated ps-1'><span className='text-uppercase'>Updated</span> : {new Date(time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</small>
                            </div>
                        </div>
                        
                        <TrackerConfig copyTracker={copyTrackerLink} handleRefresh={handleRefresh}></TrackerConfig>
                    </>
                        :
                    <div>
                    </div>
                    }
                
                                
                </>
                :
                    <div>
                    </div>
                }
                </div>
            </div>
        </>

    );
}

export default PlayerProfile;

