import React, { useState, useEffect } from 'react';

const TrackerConfig = (props) => {
  const [displayProfiles, setDisplayProfiles] = useState(true);
  const [displayFixedWidth, setDisplayFixedWidth] = useState(true);
  const [displayRank, setDisplayRank] = useState(true);
  const [displayLastUpdated, setDisplayLastUpdated] = useState(true);
  const [displayOverlay, setDisplayOverlay] = useState(true);
  const [displayNbGames, setDisplayNbGames] = useState(8);
  const [trackerSizes, setTrackerSizes] = useState([0,0]);

  const urlParams = new URLSearchParams(window.location.search);
  // Utilise l'effet pour lire les paramètres de l'URL lors du chargement initial
  useEffect(() => {
    setDisplayProfiles(urlParams.get('profiles') === '1');
    setDisplayFixedWidth(urlParams.get('width') === '1');
    setDisplayRank(urlParams.get('rank') === '1');
    setDisplayOverlay(urlParams.get('overlay') === '1');
    setDisplayLastUpdated(urlParams.get('updated') === '1');
    setDisplayNbGames(parseInt(urlParams.get('games')));
  }, []);

  useEffect(() => {
    console.log(displayNbGames)
  }, [displayNbGames]);

  useEffect(() => {
    setTrackerSize();
  }, [displayFixedWidth]);

  // Met à jour l'URL avec les nouveaux paramètres
  const updateURL = (key, value) => {
    if (key != "games"){
        urlParams.set(key, value ? '1' : '0');
    } else {
        urlParams.set(key, value);
    }
    window.history.pushState({}, '', '?' + urlParams.toString());
    
    setTrackerSize();

    props.handleRefresh()
  };

  const setTrackerSize = () => {
    const trackerElement = document.querySelector('.trackerContainer');
    setTrackerSizes([0, 0]);

    setTimeout(() => {
        const trackerElement = document.querySelector('.trackerContainer');
        let trackerWidth = 0;
        if (trackerElement) {
            if (displayFixedWidth){
                trackerWidth = 'max.'
            } else {
                trackerWidth = trackerElement.clientWidth + 20
            }
            setTrackerSizes([trackerWidth, (trackerElement.clientHeight + 20)]);
        }
    }, 300);
  }

  return (
    <div className="container trackerConfig overlay no-backdrop bg-dark rounded-3 bg-opacity-50">
      <div className="row">
        <div className="col-12">
          <h3>Settings</h3>
          <div className="form-group d-flex">
            <label>Container <small>Displays a container around the tracker</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="overlayOn" 
                name="overlay" 
                checked={displayOverlay} 
                onChange={() => {
                  setDisplayOverlay(true);
                  updateURL('overlay', true);
                }} 
              />
              <label className="form-check-label" htmlFor="overlayOn">👍</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="overlayOff" 
                name="overlay" 
                checked={!displayOverlay} 
                onChange={() => {
                  setDisplayOverlay(false);
                  updateURL('overlay', false);
                }} 
              />
              <label className="form-check-label" htmlFor="overlayOff">𐄂</label>
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Games Profile <small>Displays WPAI profiles on games</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="profilesOn" 
                name="profiles" 
                checked={displayProfiles} 
                onChange={() => {
                  setDisplayProfiles(true);
                  updateURL('profiles', true);
                }} 
              />
              <label className="form-check-label" htmlFor="profilesOn">👍</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="profilesOff" 
                name="profiles" 
                checked={!displayProfiles} 
                onChange={() => {
                  setDisplayProfiles(false);
                  updateURL('profiles', false);
                }} 
              />
              <label className="form-check-label" htmlFor="profilesOff">𐄂</label>
            </div>
          </div>

          <div className="form-group d-flex">
            <label className='d-flex flex-column'>Full Width<small>Adaptative to your stream widget size</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="fixedWidthOn" 
                name="fixedWidth" 
                checked={displayFixedWidth} 
                onChange={() => {
                  setDisplayFixedWidth(true);
                  updateURL('width', true);
                }} 
              />
              <label className="form-check-label" htmlFor="fixedWidthOn">👍</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="fixedWidthOff" 
                name="fixedWidth" 
                checked={!displayFixedWidth} 
                onChange={() => {
                  setDisplayFixedWidth(false);
                  updateURL('width', false);
                }} 
              />
              <label className="form-check-label" htmlFor="fixedWidthOff">𐄂</label>
            </div>
          </div>

          <div className="form-group d-flex">
            <label>Rank<small>Does not display if no ranked games played this season</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="rankOn" 
                name="rank" 
                checked={displayRank} 
                onChange={() => {
                  setDisplayRank(true);
                  updateURL('rank', true);
                }} 
              />
              <label className="form-check-label" htmlFor="rankOn">👍</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="rankOff" 
                name="rank" 
                checked={!displayRank} 
                onChange={() => {
                  setDisplayRank(false);
                  updateURL('rank', false);
                }} 
              />
              <label className="form-check-label" htmlFor="rankOff">𐄂</label>
            </div>
          </div>

          <div className="form-group d-flex">
            <label>Last Updated<small>Displays tracker update time</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="lastUpdatedOn" 
                name="lastUpdated" 
                checked={displayLastUpdated} 
                onChange={() => {
                  setDisplayLastUpdated(true);
                  updateURL('updated', true);
                }} 
              />
              <label className="form-check-label" htmlFor="lastUpdatedOn">👍</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="lastUpdatedOff" 
                name="lastUpdated" 
                checked={!displayLastUpdated} 
                onChange={() => {
                  setDisplayLastUpdated(false);
                  updateURL('updated', false);
                }} 
              />
              <label className="form-check-label" htmlFor="lastUpdatedOff">𐄂</label>
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Games count<small>Number of displayed games</small></label>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="nbGames4" 
                name="nbGames4" 
                checked={displayNbGames === 4}  
                onChange={() => {
                  setDisplayNbGames(4);
                  updateURL('games', 4);
                }} 
              />
              <label className="form-check-label" htmlFor="nbGames4">4</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="nbGames8" 
                name="nbGames" 
                checked={displayNbGames === 8} 
                onChange={() => {
                  setDisplayNbGames(8);
                  updateURL('games', 8);
                }} 
              />
              <label className="form-check-label" htmlFor="nbGames8">8</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="nbGames10" 
                name="nbGames" 
                checked={displayNbGames === 10} 
                onChange={() => {
                  setDisplayNbGames(10);
                  updateURL('games', 10);
                }} 
              />
              <label className="form-check-label" htmlFor="nbGames10">10</label>
            </div>
            <div>
              <input 
                className="form-check-input" 
                type="radio" 
                id="nbGames12" 
                name="nbGames" 
                checked={displayNbGames === 12} 
                onChange={() => {
                  setDisplayNbGames(12);
                  updateURL('games', 12);
                }} 
              />
              <label className="form-check-label" htmlFor="nbGames12">12</label>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
                <label className="form-label w-fit text-nowrap pe-2 mb-0">Tracker link :</label>
                <div className='d-flex'>
                  <input
                    type="text"
                    id='trackerLink'
                    className="form-control p-1 text-primary bg-body-tertiary w-100"
                    value={window.location.href.replace('cfg=1', '').replace('?&', '?')}
                    readOnly
                  />
                  <button className='btn p-1 ms-2 btn-dark' onClick={() => props.copyTracker()}>🔗</button>
                </div>
            </div>
            <p className='mt-1'>Tracker size : <span className="text-primary p-1">{trackerSizes[0]} <small className="text-white">x</small> {trackerSizes[1]}</span></p>
        </div>
      </div>
    </div>
  );
};

export default TrackerConfig;
