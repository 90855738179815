import React from 'react';
import TitreH2 from '../../Titres/TitreH2';
import './Profile.css';

const Profile = (props) => {
    const classes = `profile-block-small ${props.type}`;

    return(
        <>
            <div className={classes}>
                <TitreH2><small style={{fontSize: '8px', opacity: '0.4'}}>TOP </small>{props.top}</TitreH2>
                <small>{props.profile}</small>   
            </div>
        </>
    );
}

export default Profile;