import React from 'react';
import TitreH2 from '../../Titres/TitreH2';
import './Profile.css';

const Profile = (props) => {
    const classes = `profile-block ${props.type}`;

    return(
        <>
            <div className={classes}>
                <TitreH2 className="mb-0">{props.profile}</TitreH2>
                {props.children && <p>{props.children}</p>}
                {(props.statValue && props.statName) 
                && 
                <>
                    {(props.profile === "Pacifist" || props.profile === "Usefull") ?
                        <small>{props.statName}</small>
                    :
                        <div className='d-flex justify-content-between align-items-baseline'><small>{props.statName} : {Math.round(props.statValue * 100) / 100} </small> <small className='profile-tooltip'><span>?</span><em>{props.statDesc}</em></small></div>
                    }
                </>
                }
            </div>
        </>
    );
}

export default Profile;