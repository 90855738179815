import { useEffect, useState } from 'react';
import TitreH4 from '../Titres/TitreH4';
import Stat from './Stats/Stat';

function AllTimeStats(props) {
    const [statsFromMatches, setStatsFromMatches] = useState({});
    const [kda, setKda] = useState(0);

    useEffect(() => {
        setStatsFromMatches(props.allStats)
        //console.log(props.allStats)
    }, [props.allStats]);

    useEffect(() => {
        if(statsFromMatches) {
            animateStatValues();
            calculateKDA(statsFromMatches)
        }
    }, [statsFromMatches])

    function animateStatValues() {
        // const elements = document.querySelectorAll('.stat-value span');

        //     elements.forEach(element => {
        //         const currentNumber = element.dataset.val;
        //         let start = 0;
        //         const duration = 2400;
        //         const step = (timestamp) => {
        //             if (!start) start = timestamp;
        //             const progress = timestamp - start;
        //             const nextNumber = currentNumber * (progress / duration);

        //             element.textContent = nextNumber.toFixed(2);
        //             if (progress < duration) {
        //                 window.requestAnimationFrame(step);
        //             }
        //         };

        //         window.requestAnimationFrame(step);
                
        //         setTimeout(() => {
        //             element.textContent = element.dataset.val;
        //         }, 2500);
        //     });
    }

    function calculateKDA(stats) {
        const kills = stats.kills || 0;
        const assists = stats.assists || 0;
        
        // On considère que le joueur est mort si winPlace ≠ 1 ou si deathType est défini
        const deaths = stats.winPlace !== 1 || stats.deathType ? 1 : 0;
      
        setKda((deaths > 0 ? ((kills + assists) / deaths).toFixed(2) : (kills + assists).toFixed(2)) / statsFromMatches.matchesNbToDisplay);
      }
      

    return (

        <>
            {statsFromMatches.timeSurvived > 0 && 
                <div id='AllTimeStats' className='fullwidth mt-0 overlay py-2 py-md-4 px-3 px-md-5'>
                    <TitreH4 className="text-uppercase text-center w-75 mx-auto mb-4 fw-normal">
                        Last {statsFromMatches.matchesNbToDisplay} games stats for <b className='fw-bold'>{Math.round(kda * 100)/ 100}</b> KDA
                    </TitreH4>
                    
                    <div className='d-flex justify-content-between flex-wrap gap-2'>
                        <Stat statValue={Math.round(statsFromMatches.kills * 100)/ 100}>Total Kills</Stat> 
                        <Stat statValue={Math.round(statsFromMatches.assists * 100)/ 100}>Total Assists</Stat>  
                        <Stat statValue={Math.round(statsFromMatches.DBNOs * 100)/ 100}>Knocks</Stat>   
                        <Stat statValue={Math.round(statsFromMatches.revives * 100)/ 100}>Revives</Stat>    
                        <Stat statValue={statsFromMatches.teamKills}>TeamKills</Stat>
                        <Stat statValue={statsFromMatches.headshotKills}>Headshots</Stat>
                        <Stat statValue={(Math.round(statsFromMatches.longestKill))}>Longest Kill</Stat>
                        <Stat statValue={statsFromMatches.killStreaks}>Max. Kill Streak</Stat>
                        <Stat statValue={(Math.round(((statsFromMatches.damageDealt / statsFromMatches.matchesNbToDisplay) * 100)/ 100))}>Damages / game</Stat>   
                        <Stat statMeter={true} statValue={(Math.round(((statsFromMatches.walkDistance / statsFromMatches.matchesNbToDisplay) * 100)/ 100))}>Walk / Game</Stat> 
                        <Stat statMeter={true} statValue={(Math.round(((statsFromMatches.rideDistance / statsFromMatches.matchesNbToDisplay) * 100)/ 100))}>Ride / Game</Stat>   
                        <Stat statMeter={true} statValue={(Math.round(((statsFromMatches.swimDistance / statsFromMatches.matchesNbToDisplay) * 100)/ 100))}>Swim / game</Stat>       
                    </div>
                </div>
            }
            
        </>

    );
}

export default AllTimeStats;