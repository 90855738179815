import TitreH2 from "../../Titres/TitreH2";

function Stat(props) {

    return (

        <div className='stat-container d-flex flex-column'>
            <small>{props.children}</small> 
            <TitreH2 className="mb-0 stat-value">
            <span data-val={props.statValue}>{props.statValue}</span>
                {props.statPercent && '%'}
                {props.statMeter && 'm'}
            </TitreH2>

            {props.Additionnal && 
                <><small className='text-muted'>{props.Additionnal}</small></>
            }
        </div>  

    );
}

export default Stat;