import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ApiGet from '../Hooks/ApiGet';
import TitreH1 from '../Components/Titres/TitreH1';
import pubg from '../assets/images/pubg.png';
import AllTimeStats from '../Components/Profile/AllTimeStats';
import games from '../assets/images/ads/games.jpg';
import Matches from '../Components/Profile/Matches/Matches';
import Map from '../Components/Profile/Map/Map';
import logoWPAI from '../assets/images/logo192.png';
import Profiles from '../Components/Profiles/Profiles';
import Twitch from '../Components/Twitch/Twitch';
import TitreH5 from '../Components/Titres/TitreH5';
import GetSeason from '../Hooks/getSeason';

const profileExpirationTimeRefresh = 20;
const profileExpirationTime = profileExpirationTimeRefresh * 60 * 1000; // 20 minutes in milliseconds
const dev = false;

function PlayerProfile(props) {
    const [profileIsSet, setProfileIsSet] = useState(false);
    const [refreshTimestamp, setRefreshTimestamp] = useState();
    const [refreshTimeDiff, setRefreshTimeDiff] = useState();
    const [PlayerDatas, setPlayerDatas] = ApiGet();
    const [PlayerStats, setPlayerStats] = ApiGet();
    const [RankedStats, setRankedStats] = ApiGet();
    const [playerId, setPlayerId] = useState();
    const [Kda, setKda] = useState();
    const [Tks, setTks] = useState(0);
    const [DaysPlayed, setDaysPlayed] = useState(0);
    const [HoursPlayed, setHoursPlayed] = useState(0);
    const [Suicides, setSuicides] = useState(0);
    const [Revives, setRevives] = useState(0);
    const [SwimPerGame, setSwimPerGame] = useState(0);
    const [WalkPerGame, setWalkPerGame] = useState(0);
    const [RidePerGame, setRidePerGame] = useState(0);
    const [WinRate, setWinRate] = useState(0);
    const [Top10s, setTop10s] = useState(0);
    const [DamagesPerKill, setDamagesPerKill] = useState(0);
    const [TotalGames, setTotalGames] = useState(0);
    const [LongestKill, setLongestKill] = useState(0);
    const [reputationScore, setReputationScore] = useState(0);
    const [matches, setMatches] = useState();
    const [mostPlayed, setMostPlayed] = useState();
    const [countMostPlayed, setCountMostPlayed] = useState(0);
    const [StatsNotes, setStatsNotes] = useState([]);
    const [FinalProfiles, setFinalProfiles] = useState();
    const [matchesStats, setMatchesStats] = useState({});
    const [nbSearchs, setNbSearchs] = useState();
    const [profilesSlider, setProfilesSlider] = useState(1);
    const [singleMatchProfile, setSingleMatchProfile] = useState();
    const [currentSeason, setCurrentSeason] = GetSeason();

    // Ne pas oublier d'ajouter tout nouveau state dans la function de reset
    let profilesPUBG = JSON.parse(localStorage.getItem("Profiles-ureg-pubg")) || [];
    
    const history = useHistory();
    const TWITTER_SHARE = `${props.match.params.nom} is currently "${singleMatchProfile && singleMatchProfile[0][0].name}" on %23wpai %40PUBG_EU %40PUBG_US %40PUBG ! What about you ? Check it on :`.replace(' ', '%20');


    useEffect(() => {
        document.body.className = 'profile'
        document.title = `${props.match.params.nom} - WPAI PUBG Profile`;
    }, [props.refreshedPage])

    useEffect(() => {
        if(PlayerDatas && PlayerDatas.err && PlayerDatas.msg && PlayerDatas.type) {
            props.toAlertHandler(PlayerDatas.err, PlayerDatas.msg, PlayerDatas.type);
            history.push('/');
        } 
        
    },[PlayerDatas])

    useEffect(() => {
        if(!dev) {
            getNbSearchs()
        }
        setCurrentSeason('https://wpai-season-default-rtdb.europe-west1.firebasedatabase.app/currentSeason.json', true)
    }, [])

    useEffect(() => {
        if(currentSeason) {
            console.log(currentSeason)
        }
    }, [currentSeason])


    useEffect(() => {
        // On reset la valeur d'actualisation de la page
        if(props.refreshedPage){
            document.getElementById("logoTop").scrollIntoView();
            props.refreshPageHandler()
            setProfileIsSet(false)
            // props.refresh()
        }
    }, [props.refreshedPage])

    useEffect(() => {

        // On check le storage et on le supprime après 20min
        checkAndRemoveExpiredProfiles()

        // Infos du joueur
        if(props.match.params.nom) {
            let indexProfile = false;
            if(checkPlayerStorage(props.match.params.nom)) {
                    profilesPUBG.forEach((profile, index) => {
                        if(props.match.params.nom === profile.name) {
                            indexProfile = index;
                        }
                    });
                    
                // setPlayerId(profilesPUBG[indexProfile].data.relationships.player.data.id);
                setPlayerDatas(profilesPUBG[indexProfile], false)
            } else {
                setPlayerDatas(`https://api.pubg.com/shards/steam/players?filter[playerNames]=${props.match.params.nom}`, true)
            }
        } 
    }, [props.refreshedPage])

    useEffect(() => {
        // Set du player id
        if(PlayerDatas && PlayerDatas.data) {
            if(PlayerDatas.data[0]) {
                setPlayerId(PlayerDatas.data[0].id);
            } else {
                setPlayerId(PlayerDatas.data.relationships.player.data.id);
            }
            //console.log(PlayerDatas.data[0])
        }
    }, [PlayerDatas])

    useEffect(() => {
        // Infos du joueur lifetime
        if(playerId && !props.refreshedPage) {
            if(checkPlayerStorage(props.match.params.nom)) {
                addDatasToStates(playerId)
                
                console.log('Added from storage')
                setProfileIsSet(true)
            } else {
                setPlayerStats(`https://api.pubg.com/shards/steam/players/${playerId}/seasons/lifetime`, true)
                
                console.log('Added from api')
            }
            calculateReputationScoreHandler();
        }        
    }, [playerId, props.refreshedPage])

    useEffect(() => {
        if(PlayerStats) {
            //console.log(PlayerStats)
            if(!profileIsSet) {
                addProfile(PlayerStats)
            }
        }
    }, [PlayerStats])


    useEffect(() => {
        calculateReputationScoreHandler();
        if(PlayerStats) {
            if(PlayerStats && PlayerStats !== undefined) {
                setMatches(PlayerStats.matches);
            }
        } else {
            setMatches([]);
        }        

    }, [PlayerStats])

    useEffect(() => {
        if(PlayerStats && reputationScore) {
            giveStatNote('kda', 'KDA', Kda, [0, 0.3, 0.5, 0.6, 0.7, 1, 1.2, 1.35, 1.5, 2.5, 3.5, 5]);
            giveStatNote('repWPAI', 'Score WPAI', reputationScore, [0, 0.3, 0.5, 1, 1.2, 1.5, 2, 2.5, 3, 3.5, 5]);
            giveStatNote('longestKill', 'Longest Kill', LongestKill, [0, 100, 200, 300, 500, 600, 700, 850, 1000, 1100, 1300, 1500]);
            giveStatNote('Revives % par game', 'Revive percentage per game', (Revives / TotalGames) * 100, [0, 5, 10, 15, 19, 20, 21, 22, 25, 30,35]); 
            giveStatNote('Suicides % / game','Suicide percentage per game', (Suicides / TotalGames) * 100, [0, 0.5, 0.7, 0.9, 1, 1.5, 2, 2.5, 3, 3.5]);
            giveStatNote('tk % / game','Teamkill percentage per game', Math.round(((Tks * 100) / TotalGames) * 100) / 100, [0, 0.25, 0.5, 0.8, 1, 1.5, 1.7, 2, 2.5, 3, 3.5]);
            giveStatNote('Heures par jour', 'Hours played per day', Math.round((HoursPlayed / DaysPlayed) * 100) / 100, [0, 0.5, 0.8, 0.9, 1, 1.5, 1.7, 1.9, 2, 2.5, 3]);
            giveStatNote('Games par heures', 'Average games per hour', Math.round((TotalGames / HoursPlayed) * 100) / 100, [2, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5]);
            
            setFinalProfiles(checkProfilesStats())
        }
    },[PlayerStats, DaysPlayed])

    useEffect(() => {
        if(refreshTimestamp) {
            calcTimeDiff()

            setInterval(() => {    
                calcTimeDiff()
            }, 30000);
        }
    }, [refreshTimestamp])

    useEffect(() => {
        if(!dev) {
            postNbSearchs()
        }
    }, [nbSearchs])

    useEffect(() => {
        if(playerId) {
            document.getElementById("logoTop").scrollIntoView();
            props.refreshPageHandler(true)
            props.refresh()
        }
    }, [props.match.params.nom])

    useEffect(() => {
        if(matchesStats) {
          //console.log(matchesStats)
        }
      }, [matchesStats])

      const addMatchesStats = (newStats) => {
        setMatchesStats(newStats);
    };
  
    const addSingleProfile = (newProfile) => {
        setSingleMatchProfile(newProfile);
    };
    
    function getNbSearchs() {
            axios.get('https://stats-wpai-default-rtdb.europe-west1.firebasedatabase.app/count_searchs.json')
                .then(response => {
                    setNbSearchs(response.data)
                })
                .catch(error => {
                    console.log(error)
            })
    }

    function postNbSearchs() {
        if(nbSearchs){
            try {   
                axios.put('https://stats-wpai-default-rtdb.europe-west1.firebasedatabase.app/count_searchs.json', (nbSearchs + 1))
            .catch(error => {
                console.log(error)
            })
            } catch (error) {
                console.log(error) 
            }
        }
    }
        
    function calculateReputationScoreHandler() {
        if(!PlayerStats) return;
        let winRate = 0;
        let killsPerMatch = 0;
        let killDeathRatio = 0;
        let roundsPlayed = 0;
        let suicidesDone = 0;
        let teamMatesKilled = 0;
        let totalGames = 0;
        let longestKill = 0;
        let hoursPlayed = 0;
        let daysPlayed = 0;
        let revives = 0;
        let gameModes = 0;
        let totalWins = 0;
        let totalTops = 0;
        let totalKills = 0;
        let totalAssists = 0;
        let totalLosses = 0;
        let totalDamages = 0;
        let damagesPerKill = 0;
        let swimDistance = 0;
        let walkDistance = 0;
        let rideDistance = 0;
    
       // Iterating through all the game modes
       if(PlayerStats.data.attributes) {
        Object.keys(PlayerStats.data.attributes.gameModeStats).forEach(gameMode => {
            if(PlayerStats.data.attributes.gameModeStats[gameMode].roundsPlayed !== 0) {
                totalWins = totalWins + (PlayerStats.data.attributes.gameModeStats[gameMode].wins);
                totalTops = totalTops + (PlayerStats.data.attributes.gameModeStats[gameMode].top10s);
                totalAssists = totalAssists + (PlayerStats.data.attributes.gameModeStats[gameMode].assists);
                totalKills = totalKills + (PlayerStats.data.attributes.gameModeStats[gameMode].kills);
                totalDamages = totalDamages + (PlayerStats.data.attributes.gameModeStats[gameMode].damageDealt);
                totalLosses = totalLosses + (PlayerStats.data.attributes.gameModeStats[gameMode].losses);
                totalTops = totalTops + (PlayerStats.data.attributes.gameModeStats[gameMode].top10s);
                killsPerMatch = killsPerMatch + (PlayerStats.data.attributes.gameModeStats[gameMode].kills / PlayerStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
                totalGames = totalGames + (PlayerStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
                roundsPlayed = roundsPlayed + (PlayerStats.data.attributes.gameModeStats[gameMode].roundsPlayed);
                suicidesDone = suicidesDone + (PlayerStats.data.attributes.gameModeStats[gameMode].suicides);
                teamMatesKilled = teamMatesKilled + (PlayerStats.data.attributes.gameModeStats[gameMode].teamKills);
                hoursPlayed = hoursPlayed + (PlayerStats.data.attributes.gameModeStats[gameMode].timeSurvived);
                daysPlayed = daysPlayed + (PlayerStats.data.attributes.gameModeStats[gameMode].days);
                rideDistance = rideDistance + (PlayerStats.data.attributes.gameModeStats[gameMode].rideDistance);
                swimDistance = swimDistance + (PlayerStats.data.attributes.gameModeStats[gameMode].swimDistance);
                walkDistance = walkDistance + (PlayerStats.data.attributes.gameModeStats[gameMode].walkDistance);
                gameModes = gameModes + 1;
            }
            if(gameMode !== "solo" || gameMode !== "solo-fpp")
                revives = revives + (PlayerStats.data.attributes.gameModeStats[gameMode].revives);
            
            if(longestKill < PlayerStats.data.attributes.gameModeStats[gameMode].longestKill) {
                longestKill = PlayerStats.data.attributes.gameModeStats[gameMode].longestKill;
            }
        });
       }

        let swimDistancePerGame = Math.round((swimDistance / totalGames) * 100) / 100;
        let walkDistancePerGame = Math.round((walkDistance / totalGames) * 100) / 100;
        let rideDistancePerGame = Math.round((rideDistance / totalGames) * 100) / 100;

       
        damagesPerKill = (totalDamages / totalKills);
        killDeathRatio = (totalKills + totalAssists) / totalLosses;
        winRate = (totalWins / totalGames);

        setSwimPerGame(swimDistancePerGame);
        setWalkPerGame(walkDistancePerGame);
        setRidePerGame(rideDistancePerGame);
        setDamagesPerKill(damagesPerKill);
        setKda(killDeathRatio);
        setDamagesPerKill( totalDamages / totalKills)
        setSuicides(suicidesDone);
        setTks(teamMatesKilled);
        setTop10s((totalTops / totalGames) * 100)
        setWinRate((winRate) * 100);
        setRevives(revives);
        setLongestKill(longestKill);
        setTotalGames(totalGames);
        setKda(killDeathRatio);
        setHoursPlayed((hoursPlayed / 3600));
        setDaysPlayed(daysPlayed);


        // Normalize by the number of game modes for rep
        winRate /= gameModes;
        killsPerMatch /= gameModes;
        killDeathRatio /= gameModes;
        suicidesDone /= gameModes;
        teamMatesKilled /= gameModes;
    
        // You can use different weights for each factor or not
        const reputationScore = (winRate * 1) + (damagesPerKill * .002) + (killsPerMatch * .7) + (killDeathRatio * .8)  + ((suicidesDone / totalGames) * -5) + ((teamMatesKilled / totalGames) * -10) + (((totalTops / totalGames) * 100) * .005);
        setReputationScore(reputationScore);
    }



    function addProfile(profile) {
        const currentTimestamp = Date.now();
        if(PlayerStats && PlayerStats.data) {
            profile.timestamp = currentTimestamp;
            profile.name = props.match.params.nom;
            if(PlayerDatas && PlayerDatas !== undefined) {
                profile.matches = PlayerDatas.data[0].relationships.matches.data;
            }

            profilesPUBG.push(profile);
            localStorage.setItem("Profiles-ureg-pubg", JSON.stringify(profilesPUBG));

            setRefreshTimestamp(currentTimestamp)
        }
        setProfileIsSet(true)
    }

    function checkAndRemoveExpiredProfiles() {
        let StorageProfiles = JSON.parse(localStorage.getItem("Profiles-ureg-pubg")) || [];

        StorageProfiles.forEach((profile, index) => {

            if (moment().diff(profile.timestamp, 'minutes') >= profileExpirationTimeRefresh) {
                StorageProfiles.splice(index, 1);
            }
        });
        localStorage.setItem("Profiles-ureg-pubg", JSON.stringify(StorageProfiles));
    }

    function checkPlayerStorage(playerId) {
        let playerData = null;
                
        for (let i = 0; i < profilesPUBG.length; i++) {
            if (playerId === profilesPUBG[i].name) {
                playerData = true;
                setRefreshTimestamp(profilesPUBG[i].timestamp)
            }
        }
        

        if (playerData === null) {
          // console.log(`Le stockage pour le joueur ${playerId} n'est pas défini`);
          return false;
        }
        // console.log(`Le stockage pour le joueur ${playerId} est défini`);
        return true;
      }

      function addDatasToStates(playerId) {
        let currentProfile = null;
        profilesPUBG.forEach((profile, index) => {
            if (playerId === profile.data.relationships.player.data.id) {
                    currentProfile = profilesPUBG.splice(index, 1);
            }
        });
        
        if(!PlayerStats) {
            setPlayerStats(currentProfile[0], false)
        }
      }

        function calcTimeDiff() {
            if(refreshTimestamp) {
                const regTimestamp = moment().diff(refreshTimestamp, 'minutes');  
                let timeDiff = profileExpirationTimeRefresh - regTimestamp; 
                
                if(timeDiff <= 0) {
                    timeDiff = 0;
                }

                setRefreshTimeDiff(timeDiff);
                checkAndRemoveExpiredProfiles();
            }
        }

      function copyProfileLink() {
        props.toAlertHandler(200,'Link copied', 'info')
        navigator.clipboard.writeText(window.location.href).then(function() {}, function(err) {console.error('Erreur lors de la copie dans le presse-papier : ', err);});
      }

      function getMostPlayedHandler(map) {
        setMostPlayed(map.toLowerCase());
      }

      function getCountMostPlayedHandler(countmap, nb) {
        setCountMostPlayed((countmap / nb) * 100);
      }
    
      function giveStatNote(stat,title,statVal,levels) {
          let highestNote = 0;
          let statObject = {
            name: stat,
            title: title,
            baseVal: statVal,
            highestNote: highestNote,
            diff : null,
            diffType: null
          };
          const newNotes = StatsNotes;
          let noteExist = false;
    
        if(statVal && !isNaN(statVal)) {
            // On boucle dans les valeurs pour définir son niveau
            for (let i = 0; i <= levels.length; i++) {
                if(statVal > levels[i]) {
                    highestNote = (i + 1);
                }
            }
            
            if(newNotes.length > 0) {
                // On check sur la stat existe déjà dans le tableau de Notes pour la remplacer
                for (let i = 0; i < newNotes.length; i++) {
                    if(stat === newNotes[i].name) {
                        newNotes[i].highestNote = highestNote;
                        noteExist = true;

                        if((levels.length - highestNote) < (0 + highestNote)) {
                            newNotes[i].diff = (levels.length - highestNote);
                            newNotes[i].diffType = "Top";
                        } else {
                            newNotes[i].diff = (0 + highestNote);
                            newNotes[i].diffType = "Bottom"
                        }
                    }
                }
            }
            
            if(noteExist === false) {
                statObject.highestNote = highestNote;
                
                if((levels.length - highestNote) < (0 + highestNote)) {
                    statObject.diff = (levels.length - highestNote);
                    statObject.diffType = "Top";
                } else {
                    statObject.diff = (0 + highestNote);
                    statObject.diffType = "Bottom";
                }

                newNotes.push(statObject)
            }

            newNotes.sort((a, b) => a.diff - b.diff);

            setStatsNotes(newNotes);
        }
    }

    function checkProfilesStats() {
        if(StatsNotes) {
            const nbProfiles = 4;
            const stats = StatsNotes;
            const profiles = Profiles;
            
            let resultProfiles = stats.slice(0,nbProfiles).map(objStats => {
                let objProfiles = profiles.find(obj => (obj.stat === objStats.name) && (obj.type === objStats.diffType));
                return {...objStats, ...objProfiles};
              });
              
              for (let i = 0; i < resultProfiles.length; i++) {
                resultProfiles[i].desc = resultProfiles[i].desc.replace('PLAYERNAME', props.match.params.nom)
              }
            
              return(resultProfiles);
        }
    }


    return (
        <>
        
        <div id="logoTop" className='logo-profil'>
            <NavLink to="/">
                <img src={logoWPAI} alt="Logo WPAI" />
            </NavLink>
            {/* <button onClick={props.refreshPageHandler}>refresh</button> */}
        </div>
            
            <div className='container'>
                <TitreH1 className="profileTitle w-100 mt-4 mt-md-5">What kind of player is <span>{props.match.params.nom && `${props.match.params.nom}`}</span> on <img src={pubg} alt="PUBG Logo" /> ?</TitreH1>
                
                <div className='socials-container d-flex align-items-center flex-wrap mt-2 mt-md-3 mb-4'>
                    <p className='text-uppercase mb-0'>Share profile</p>

                    <div className='d-flex justify-content-between'>
                        <span className='mx-2 d-none d-sm-block'></span>
                        <a className='btn btn-info btn-sm rounded-0' href={`https://twitter.com/share?url=${window.location.href}&text=${TWITTER_SHARE}`} target="_blank">TWITTER</a>
                        <span className='mx-1'></span>
                        <button className='btn btn-light btn-sm rounded-0' onClick={() => copyProfileLink()}>Copy link</button>
                        <NavLink className='btn btn-dark btn-sm rounded-0 mx-2 h-100' to={props.match.params.nom && `/tracker/${props.match.params.nom}?cfg=1&overlay=1&profiles=1&width=0&rank=1&updated=1&games=8`}>
                            <span>WPAI Tracker</span>
                        </NavLink>
                        
                    </div>
                </div>

            {!props.refreshedPage ? 
            <>
            {(PlayerStats) ? 
                <>
                    {(PlayerStats && (matches && matches.length > 0)) &&
                        <>
                            <div id="matchesDiv" className='fullwidth mb-2 p-4'>
                                <div className='rowMatches row-grided'>
                                    <Matches singleMatchProfile={singleMatchProfile} addSingleProfile={addSingleProfile} addMatchesStats={addMatchesStats} refreshPageHandler={props.refreshPageHandler} refreshTimeDiff={refreshTimeDiff} nom={props.match.params.nom} refresh={props.refresh} getCountMostPlayedHandler={getCountMostPlayedHandler} getMostPlayedHandler={getMostPlayedHandler} matchesId={matches} playerId={playerId} />
                                </div>
                            </div>

                        </>
                    }  
                    {matches && matches.length === 0 && 
                    <div className='overlay-light p-2 mt-1 mb-2'>
                    <TitreH5 className="w-100 text-center">{props.match.params.nom} has no game played in the last 14 days</TitreH5>
                    <p className="w-100 text-center"><small>Due to the lack of matches datas, nothing is appearing here and there is no "Most Played Map"</small></p>
                    </div>
                    }
                    
                    {mostPlayed && 
                        <Map countMostPlayed={countMostPlayed} totalPlayed={(PlayerStats && PlayerStats.matches !== undefined) && PlayerStats.matches.length} mostPlayedMap={mostPlayed} />
                    }

                    {matchesStats &&
                        <>
                            <AllTimeStats 
                                player={props.match.params.nom}
                                allStats={matchesStats}
                            />
                        </>
                    }
                   
                     <Twitch nom={props.match.params.nom} /> 
                     {props.match.params.nom == "mikeu" &&
                        <a href={"https://store.steampowered.com/search/?developer=Patate%20Douce%20Studio"} target='_blank' className='col-12 p-2'>
                            <img className='w-100 rounded-2' src={games} alt="games" />
                        </a>
                     }
                </>
                    :
                <div>
                    <img src='https://media2.giphy.com/media/dAcUSqS5cT5oIt75jS/giphy.gif?cid=790b7611b213d5584648958f8b02cb18291f5137a0408421&rid=giphy.gif&ct=s' alt="loader" />
                </div>
                }
            
                            
            </>
            :
                <div>
                    <img src='https://media2.giphy.com/media/dAcUSqS5cT5oIt75jS/giphy.gif?cid=790b7611b213d5584648958f8b02cb18291f5137a0408421&rid=giphy.gif&ct=s' alt="loader" />
                </div>
            }
            </div>
        </>

    );
}

export default PlayerProfile;

