import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TitreH1 from '../Components/Titres/TitreH1';
import logo from '../assets/images/logo.png';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import TitreH2 from '../Components/Titres/TitreH2';


const validationSchema = Yup.object({
  NomPUBG: Yup.string()
    .min(3, 'PUBG name must be at least 3 characters')
    .max(30, 'PUBG name can\'t be more than 30 characters')
    .required('PUBG name required'),
  chaineTwitch: Yup.string()
    .min(3, 'Twitch name must be at least 3 characters')
    .max(30, 'Twitch name can\'t be more than 30 characters')
    .required('Twitch name required'),
});

const AskTwitch = (props) => {
    const [chaines, setChaines] = useState();
    const [submitted, setSubmitted] = useState(false);
    const dureeSubmission = 3600000; // 1h

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        let HasBeenSubmited = checkIfSubmitted();
        const now = Date.now();

        if(HasBeenSubmited) {
            props.toAlertHandler(666,`You already submitted this form`, 'danger');
            return;
        }

        // ajoutez le timestamp de la soumission actuelle au localStorage
        localStorage.setItem('wpai_submitform_timestamp', now);

        setTimeout(() => {
            props.toAlertHandler(1337,`Form submitted with success`, 'success')
        resetForm({});
        setSubmitting(false);

            let newChaines = chaines;
            newChaines.push({
                chaine: values.chaineTwitch,
                id: chaines.length + 1,
                nompubg: values.NomPUBG,
                validate: false
            })
            setChaines(newChaines.sort((a, b) => b.id - a.id));
            updateTwitchDatas();
        }, 500);
    };

    useEffect(() => {       
        getTwitchDatas();
        checkIfSubmitted();
    }, []);

  
    const checkIfSubmitted = () => {
        const lastSubmissionTimestamp = localStorage.getItem('wpai_submitform_timestamp');
        const now = Date.now();
        if (lastSubmissionTimestamp && now - lastSubmissionTimestamp < dureeSubmission) {
            setSubmitted(true);
            return true;
        } else {
            localStorage.removeItem("wpai_submitform_timestamp");
        }
    }

    const getTwitchDatas = () => {
        axios.get('https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/Chaines.json')
        .then(reponse => {
            const Array = Object.values(reponse.data)
            setChaines(Array);
        })
        .catch(error => {
            console.log(error)
     })
    }

    const updateTwitchDatas = () => {
        axios.put(`https://wpai-twitch-default-rtdb.europe-west1.firebasedatabase.app/Chaines.json`, chaines)
            .then(response => {
            })
            .catch(error => {
                console.log(error);
            });
    }

  return (
    <div className='container-sm container-perso-home'>
        <NavLink to={'/'}>
            <div className='col-12 py-3 pt-md-4 text-center logo'>
                <img src={logo} alt="Logo WPAI : What Player Am I" />
            </div>
        </NavLink>

        <div className='row'>
            <TitreH1 className="w-100 mt-3 mt-md-4">Add my Twitch channel</TitreH1>
            <p className='mb-3 mb-md-4'>
                Ask us to add your twitch channel on your profile page. Validation can take up to 3-5 days as It is a human validation.
            <br/>
                Please enter your EXACT twitch and PUBG names (with uppercases and lowercases).
            </p>
            {submitted ? (
            <div className='btn btn-outline-info disabled bg-light'><TitreH2 className="mb-1">You already submitted this form !</TitreH2> <br/>You can only submit it once an hour. <br/>Come back later !</div>
                ) : 
                <>
                    <Formik
                        initialValues={{
                            NomPUBG: '',
                            chaineTwitch: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className='container'>
                                <div className='form-group row'>
                                    <div className='d-flex flex-md-column mb-2'>
                                        <Field className="form-control px-2" type="text" name="NomPUBG" placeholder="PUBG Name" />
                                        <small><ErrorMessage className='text-bg-danger p-1 mb-2 mt-1 rounded-2' name="NomPUBG" component="div" /></small>
                                    </div>
                                    <div className='d-flex flex-md-column mb-2'>
                                        <Field className="form-control px-2" type="text" name="chaineTwitch" placeholder="Twitch Link" />
                                        <small><ErrorMessage className='text-bg-danger p-1 mb-2 mt-1 rounded-2' name="chaineTwitch" component="div" /></small>
                                    </div>
                                </div>
                            <button className='btn btn-primary' type="submit" disabled={isSubmitting}>
                                Add your twitch
                            </button>
                            </Form>
                        )}
                    </Formik>
                </>
            }
            
        </div>
        
    </div>
    
  );
};

export default AskTwitch;
