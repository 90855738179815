import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import Bouton from '../Components/Boutons/Bouton';
import '../assets/css/Accueil.css';
import logo from '../assets/images/logo.png';
import games from '../assets/images/ads/games.jpg';
import TitreH1 from '../Components/Titres/TitreH1';
import TitreH6 from '../Components/Titres/TitreH6';
import ModalSearchPlayers from '../Components/Accueil/ModalSearchPlayers';
import FAQitem from '../Components/Accueil/FAQitem';

function Accueil() {
    const [nomJoueur, setNomJoueur] = useState("");
    const [listeJoueurs, setListeJoueurs] = useState([]);
    const [showListe, setShowListe] = useState(false);
    const [nbVisites, setNbVisites] = useState();
    const history = useHistory();

    useEffect(() => {
        document.body.className = ''
        document.title = `WPAI : What Player Am I`;
    }, [])

    useEffect(() => {
        let playerStorage = readLocalStorage();

        setListeJoueurs(playerStorage);
        getNbVisites()
    }, [])

    useEffect(() => {
      postNbVisites()
    }, [nbVisites])

    function sendPlayerNameHandler(nom) {
        if(typeof(nom) === "string") {
            history.push('/profile/'+nom);
        } 
        else {
            history.push('/profile/'+nomJoueur);
            writeLocalStorage(nomJoueur)
        }
    }

    function readLocalStorage() {
        let playerStorage;
        try {
          playerStorage = JSON.parse(localStorage.getItem("PlayerStorage"));
        } catch(e) {
          console.log("Error reading from local storage: ", e);
        }
        return playerStorage;
      }

    function writeLocalStorage(newData) {
        let playerStorage = readLocalStorage();
        if(!playerStorage) {
          playerStorage = [];
        }

        if(!playerStorage.includes(newData)) {
            playerStorage.push(newData)

            try {
                localStorage.setItem("PlayerStorage", JSON.stringify(playerStorage));
              } catch(e) {
                console.log("Error writing to local storage: ", e);
              }

              setListeJoueurs(playerStorage)
        }
      }

      function removeFromLocalStorage(itemToRemove) {
        const data = JSON.parse(localStorage.getItem("PlayerStorage"));
        
        if (data && Array.isArray(data)) {
          const index = data.indexOf(itemToRemove);
          
          if (index !== -1) {
            data.splice(index, 1);

            localStorage.setItem("PlayerStorage", JSON.stringify(data));
          }

            setListeJoueurs(data);
        }
      }

      function showListeHandler() {
        let playerStorage = readLocalStorage();
        if(!playerStorage) {
            setShowListe(false)
        } else {
            setShowListe(true)
        }
      }

      function getNbVisites() {
        axios.get('https://stats-wpai-default-rtdb.europe-west1.firebasedatabase.app/visites.json')
            .then(response => {
                setNbVisites(response.data)
            })
            .catch(error => {
                console.log(error)
        })
      }

      function postNbVisites() {
          try {   
              axios.put('https://stats-wpai-default-rtdb.europe-west1.firebasedatabase.app/visites.json', (nbVisites + 1))
              .catch(error => {
                  console.log(error)
              })
              } catch (error) {
              console.log(error) 
              }
      }


    return (

        <>
            <div className='container container-perso-home'>
                <div className='row'>
                    <div className='col-12 my-4 text-center logo'>
                        <img src={logo} alt="Logo WPAI : What Player Am I" />
                    </div>
                    
                    <TitreH1 className='col-12 m-0 text-center text-uppercase'>
                        TYPE A PLAYER NAME to find out
                        <br />
                        what type of player he/she is ! *
                    </TitreH1>
                    
                    <div className='col-12 position-relative my-4 mt-md-3' onBlur={() => setTimeout(() => {setShowListe(false)}, 200)} >
                        <span className='input-notice btn btn-outline-primary btn-sm disabled'>What TYPE OF PLAYER IS:</span>
                        <input type="text" className='form-control' placeholder='Exemples : LittleBigWhale, TGLTN, ...' value={nomJoueur} onFocus={() => showListeHandler()} onChange={(event) => setNomJoueur(event.target.value)} />
                        <Bouton className="btn-dark btn-shoot" click={sendPlayerNameHandler}>Shoot ➡</Bouton>
                        {showListe && <ModalSearchPlayers sendPlayerNameHandler={sendPlayerNameHandler} removeFromLocalStorage={removeFromLocalStorage} listeJoueurs={listeJoueurs} />}
                    </div>

                    <p className='col-12 text-center'>
                        Every profiles can change after each game. Every stat can have a bad and a good profile.
                    </p>
                </div>
                <div className='row my-4'>
                  <div className='col-12 p-2'>
                    <div className='overlay faq'>
                      <TitreH6 className='w-100'>FAQ</TitreH6>
                      
                      <FAQitem 
                        active={true}
                        click={(e) => e.target.closest('div').classList.toggle("active")}
                        question='How can I have my stream displayed on my profile page ?'>
                        If you desire to see your stream displayed on your page, you have use <NavLink to="/addmytwitch">this form</NavLink> to ask. <br /><small>(Validation can take up to 3-5 days)</small>                      </FAQitem>
                      <FAQitem 
                        click={(e) => e.target.closest('div').classList.toggle("active")}
                        question='How are calculated the displayed profiles ?'>
                        Long story short, a note from 0 to 11 is attributed to a stat and the closer from 11 and 0 you get, the more you have a chance to see this profile displayed. Althought the exact calculation would be long to explain. Moreover, If you have recently changed your PUBG nickname try searching for the previous name, as it can take some time before that updates.
                      </FAQitem>
                      <FAQitem 
                        click={(e) => e.target.closest('div').classList.toggle("active")}
                        question='When I enter my player name it says "Invalid player name"'>
                        If you see this error, this means your in-game name is not the one you enter or you didn't respect the uppercases and lowercases.
                      </FAQitem>
                      <FAQitem 
                        click={(e) => e.target.closest('div').classList.toggle("active")}
                        question='I got the error "too many requests"'>
                        If you see this error, this means we have reached our requests limit. Wait a minute and retry !
                      </FAQitem>
                      <FAQitem 
                        click={(e) => e.target.closest('div').classList.toggle("active")}
                        question="How far in the past can the data go?">
                        The official PUBG API can only retain the last 14 days of matches per player. However, AllTimeStats section and Profiles will still be active.
                      </FAQitem>
                    </div>
                  </div>

                  <a href={"https://store.steampowered.com/search/?developer=Patate%20Douce%20Studio"} target='_blank' className='col-12 p-2'>
                    <img className='w-100 rounded-2' src={games} alt="games" />
                  </a>
                </div>
            </div>
        </>

    );
}

export default Accueil;