const Profiles = [
        {
            "id": 1,
            "name": "Traitor",
        "short": "Traitor",
            "desc": "Collateral damages are ok for PLAYERNAME.",
            "stat": "tk % / game",
            "type": "Top",
            "alertype" : "redlight"
        },
        {
            "id": 2,
            "name": "Crewmate",
        "short": "Crewmate",
            "desc": "PLAYERNAME prefers to die if any mate is too stupid to get off of his/her sight.",
            "stat": "tk % / game",
            "type": "Bottom",
            "alertype" : "success"
        },
        {
            "id": 3,
            "name": "Elite Sniper",
        "short": "Elite Sniper",
            "desc": "Ennemies are affraid of PLAYERNAME. They can die without even hearing the sound of the shot.",
            "stat": "longestKill",
            "type": "Top",
            "alertype" : "success"
        },
        {
            "id": 4,
            "name": "Baby Sniper",
        "short": "Baby Sniper",
            "desc": "PLAYERNAME can't see really far. Doesn't mean he/she is a bad player. Just a blind one.",
            "stat": "longestKill",
            "type": "Bottom",
            "alertype" : "warning"
        },
        {
            "id": 5,
            "name": "Nurse",
            "short": "Nurse",
            "desc": "On the battlefield, noone is better than PLAYERNAME to revive and help people !",
            "stat": "Revives % par game",
            "type": "Top",
            "alertype" : "success"
        },
        {
            "id": 6,
            "name": "Solo Player",
            "desc": "What are team mates ? PLAYERNAME does not care about his/her fallen team mates !",
            "stat": "Revives % par game",
            "type": "Bottom",
            "alertype" : "warning"
        },
        {
            "id": 7,
            "name": "Kamikaze",
            "stat": "Suicides % / game",
            "desc": "Running into the ennemies is PLAYERNAME's gameplay",
            "type": "Top",
            "alertype" : "danger"
        },
        {
            "id": 8,
            "name": "Survivor",
            "stat": "Suicides % / game",
            "desc": "Life is more important than anything to PLAYERNAME. Surviving is the key.",
            "type": "Bottom",
            "alertype" : "info"
        },
        {
            "id": 9,
            "name": "Retry",
            "stat": "Games par heures",
            "desc": "PLAYERNAME dies way too much. His/her gameplay is based on learning from mistakes, but he/she never does.",
            "type": "Top",
            "alertype" : "warning"
        },
        {
            "id": 10,
            "name": "Tryhard",
            "stat": "Games par heures",
            "desc": "PLAYERNAME doesn't die too much because he/she is too good. (or a camper ?)",
            "type": "Bottom",
            "alertype" : "info"
        },
        {
            "id": 11,
            "name": "Overskilled",
            "stat": "kda",
            "desc": "PLAYERNAME is way too good for this game. Really.",
            "type": "Top",
            "alertype" : "success"
        },
        {
            "id": 12,
            "name": "Underskilled",
            "stat": "kda",
            "desc": "At least PLAYERNAME has fun on this game. We do hope so.",
            "type": "Bottom",
            "alertype" : "black"
        },
        {
            "id": 13,
            "name": "Special forces",
            "stat": "repWPAI",
            "desc": "To us, PLAYERNAME has been trained to destroy the ennemies.",
            "type": "Top",
            "alertype" : "primary"
        },
        {
            "id": 14,
            "name": "Fun player",
            "stat": "repWPAI",
            "desc": "Our algorithm says PLAYERNAME is bad ... but maybe fun to play with ?",
            "type": "Bottom",
            "alertype" : "info"
        },
        {
            "id": 15,
            "name": "Nerd",
            "stat": "Heures par jour",
            "desc": "PLAYERNAME is playing WAY TOO MUCH this game. Take a break sometime !",
            "type": "Top",
            "alertype" : "danger"
        },
        {
            "id": 16,
            "name": "Busy",
            "stat": "Heures par jour",
            "desc": "Doesn't he/she like the game ? Why is PLAYERNAME playing so few ?",
            "type": "Bottom",
            "alertype" : "warning"
        },
        {
            "id": 17,
            "name": "Taxi Driver",
            "short": "Taxi Driver",
            "stat": "drive per game",
            "desc": "PLAYERNAME doesn't count the kilometers !",
            "type": "Top",
            "alertype" : "warning"
        },
        {
            "id": 18,
            "name": "Forrest Gump",
            "short": "Forrest Gump",
            "stat": "walk per game",
            "desc": "PLAYERNAME is a runner. Forrest Gump would be proud !",
            "type": "Top",
            "alertype" : "success"
        },
        {
            "id": 19,
            "name": "Aquaman",
            "short": "Aquaman",
            "stat": "swim per game",
            "desc": "PLAYERNAME is as good as a fish while in the water.",
            "type": "Top",
            "alertype" : "info"
        },
        {
            "id": 20,
            "name": "Respawn",
            "short": "Respawn",
            "stat": "early death",
            "desc": "PLAYERNAME died at the start. Just relaunch a game...",
            "type": "Top",
            "alertype" : "danger"
        },
        {
            "id": 21,
            "name": "Hitman",
            "short": "Hitman",
            "stat": "killsdone",
            "desc": "PLAYERNAME is a real hitman. Killing everybody on the way.",
            "type": "Top",
            "alertype" : "danger"
        },
        {
            "id": 22,
            "name": "Support",
            "short": "Support",
            "stat": "assistsdone",
            "desc": "PLAYERNAME is someone you can count on. He will always help you win the chicken.",
            "type": "Top",
            "alertype" : "info"
        },
        {
            "id": 23,
            "name": "Damages maker",
            "short": "Dmg maker",
            "stat": "damagesPerKill",
            "desc": "PLAYERNAME shoots on everything !",
            "type": "Top",
            "alertype" : "black"
        },
        {
            "id": 24,
            "name": "Junky",
            "short": "Junky",
            "stat": "boosts",
            "desc": "PLAYERNAME use SO MANY boosts. As much as a junky.",
            "type": "Top",
            "alertype" : "primary"
        },
        {
            "id": 25,
            "name": "Sandman",
            "short": "Sandman",
            "stat": "dbnos",
            "desc": "PLAYERNAME knocks so many players without killing them ! ",
            "type": "Top",
            "alertype" : "warning-subtle"
        },
        {
            "id": 26,
            "name": "Roadhog",
            "short": "Roadhog",
            "stat": "roadkills",
            "desc": "PLAYERNAME is a real Roadhog. GET OUT OF THE WAY!",
            "type": "Top",
            "alertype" : "primary"
        },
        {
            "id": 27,
            "name": "Tank",
            "short": "Tank",
            "stat": "vehicleDestroys",
            "desc": "Vehicles can't resist PLAYERNAME's strengh.",
            "type": "Top",
            "alertype" : "primary"
        },
        {
            "id": 28,
            "name": "Fraudster",
            "short": "Fraud",
            "stat": "killsPardamages",
            "desc": "PLAYERNAME loves to steal kills from people.",
            "type": "Top",
            "alertype" : "primary"
        },
        {
            "id": 29,
            "name": "LEGEND",
            "short": "LEGEND",
            "stat": "legend",
            "desc": "PLAYERNAME is a true legend.",
            "type": "Top",
            "alertype" : "legend"
        },
        {
            "id": 30,
            "name": "Nice try",
            "short": "Nice try",
            "stat": "killsdoneLow",
            "desc": "PLAYERNAME kills people but dies early. Nice try...",
            "type": "Top",
            "alertype" : "gradient"
        },
    {
                "id": 31,
                "name": "Usefull",
                "short": "Usefull",
                "stat": "killsdoneLowHigh",
                "desc": "PLAYERNAME has been pretty usefull !",
                "type": "Top",
                "alertype" : "info"
            },
        {
                "id": 32,
                "name": "Collector",
                "short": "Collector",
                "stat": "weaponsAcquired",
                "desc": "PLAYERNAME spends more time collecting weapons than killing people",
                "type": "Top",
                "alertype" : "purple"
            },
        {
                "id": 33,
                "name": "Contributor",
                "short": "Contributor",
                "stat": "Contributor",
                "desc": "PLAYERNAME participates in games but no more...",
                "type": "Top",
                "alertype" : "secondary"
            },
        {
                "id": 34,
                "name": "Head Hunter",
                "short": "Head Hunter",
                "stat": "Head Hunter",
                "desc": "PLAYERNAME is aiming for the head only.",
                "type": "Top",
                "alertype" : "danger"
            },
        {
                "id": 35,
                "name": "Survivor",
                "short": "Survivor",
                "stat": "Survivor",
                "desc": "PLAYERNAME is a real survivor. Who cares about taking damages when bandages exists ?",
                "type": "Top",
                "alertype" : "lightgreen"
            },
    ];
    
    
    export default Profiles;