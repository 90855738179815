import { useEffect, useState } from 'react';
import Match from './Match';
import TitreH1 from '../../Titres/TitreH1';
import TitreH5 from '../../Titres/TitreH5';
import '../../../assets/css/SingleMatch.css';
import axios from 'axios';
import Key from '../../../Constants/Consts';
import Loading from '../../Loading/Loading';
import Profile from '../../Profiles/Profile/Profile';

function Matches(props) {
    const matches = props.matchesId;
    const [fetchApi, setfetchApi] = useState();
    const PUBG_API_KEY = Key();
    const matchesNbToCount = 36;
    const [matchesNbToDisplay, setMatchesNbToDisplay] = useState(12);
    const [matchesStats, setMatchesStats] = useState([]);
    const [matchesProfile, setMatchesProfile] = useState([]);
    const [TopProfile, setTopProfile] = useState();
    const [FinalMatchesStats, setFinalMatchesStats] = useState({});

    useEffect(() => {
        if(matches) {       
 
          axios.post('https://wpai-cors.patatedouce-studio.fr/api/matches/', 
            {
              data : matches,
              key : PUBG_API_KEY,
              nbmatches : 40,
              excluded : ['tdm']
            },
            {
              mode: 'no-cors'
            }
          )
        
          .then(reponse => {
            if(reponse.data) {
              // console.log(reponse.data)
              let filteredMatches = []
              
              reponse.data.filter(match => {
                // console.log(match.data.attributes.matchType)
                if(match.data.attributes.matchType != "airoyale") {
                  filteredMatches.push(match)
                }
              })

              setfetchApi(filteredMatches)
            }
          })
          .catch(error => {
            console.log(error)
          })

        }
    }, [])

    useEffect(() => {
      if(fetchApi && fetchApi !== undefined) {
        props.getCountMostPlayedHandler(checkMostPlayedMap()[1], matchesNbToCount);
        props.getMostPlayedHandler(checkMostPlayedMap()[0]);
      }
    }, [fetchApi])

    useEffect(() => {
      if(matchesProfile && matchesProfile.length === matchesNbToDisplay) {
        checkTopProfile();
      }
    }, [matchesProfile])

    useEffect(() => {
      if(TopProfile) {
        props.addSingleProfile(TopProfile);
      }
    }, [TopProfile])

    useEffect(() => {
      if(matchesStats) {
        // console.log(matchesStats)
      }
    }, [matchesStats])

    useEffect(() => {
      if(matchesStats) {
        calculateFinalStats(matchesStats)
      }
    }, [matchesStats])

    useEffect(() => {
      if(FinalMatchesStats) {
        props.addMatchesStats(FinalMatchesStats)
      }
    }, [FinalMatchesStats])

    useEffect(() => {
      if(matchesProfile) {
        // console.log(matchesProfile)
      }
    }, [matchesProfile])

    function checkTopProfile() {
      let profileMap = {};
      let topProfiles = [];
    
      matchesProfile.forEach(match => {
        const profile = match[0]; // Récupère le premier profil de chaque match
        
        if(profile !== undefined) {
          // Si le profil est déjà dans profileMap
          if (profileMap[profile.name]) {
            profileMap[profile.name].count++;
          } else {
            // Ajoute le profil avec un compteur initial à 1
            profileMap[profile.name] = { ...profile, count: 1 };
          }
        }
        
      });
    
      // Convertir l'objet en tableau et trier les profils par fréquence
      topProfiles = Object.values(profileMap).sort((a, b) => b.count - a.count);
      
      //console.log(topProfiles)
      setTopProfile([topProfiles]); 
    }

    const addProfile = (newProfile, playerStats) => {
      setMatchesProfile((prevProfiles) => [...prevProfiles, newProfile]); // Ajoute le profil
      setMatchesStats((prevStats) => [...prevStats, playerStats]);
    };

    function calculateFinalStats(matches) {
      let newStats = {
        "DBNOs": 0,
        "assists": 0,
        "boosts": 0,
        "damageDealt": 0,
        "headshotKills": 0,
        "heals": 0,
        "killStreaks": 0,
        "kills": 0,
        "longestKill": 0,
        "revives": 0,
        "rideDistance": 0,
        "roadKills": 0,
        "swimDistance": 0,
        "teamKills": 0,
        "timeSurvived": 0,
        "vehicleDestroys": 0,
        "walkDistance": 0,
        "weaponsAcquired": 0,
        "winPlace": 0,
        "matchesNbToDisplay":0
      };
    
      // Boucle sur chaque match pour cumuler les stats
      matches.forEach(match => {
        Object.keys(newStats).forEach(stat => {
          if (typeof match[stat] === "number") {
            if (stat === "longestKill" || stat === "killStreaks") {
              // Prendre le maximum au lieu de faire une addition
              newStats[stat] = Math.max(newStats[stat], match[stat]);
            } else {
              // Additionner les autres valeurs normalement
              newStats[stat] += match[stat];
            }
          }
        });
      });
      newStats['matchesNbToDisplay'] = matchesNbToDisplay
    
      // Met à jour le state React avec les nouvelles valeurs
      setFinalMatchesStats(prevStats => ({ ...prevStats, ...newStats }));
    }
    
    
    

    function checkMostPlayedMap() {
      let mapsCount = {
          Vikendi : 0,
          Erangel : 0,
          Sanhok : 0,
          Miramar : 0,
          Deston : 0,
          Paramo : 0,
          Haven : 0,
          Karakin : 0,
          Taego : 0,
          Jackal : 0,
          Rondo : 0
      }


      if(fetchApi && fetchApi !== undefined) {
          for (let i = 0; i < fetchApi.length; i++) {
              switch (fetchApi[i].data.attributes.mapName) {
                  case "Baltic_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Chimera_Main":
                      mapsCount.Paramo = mapsCount.Paramo + 1;
                    break;
                  case "Desert_Main":
                      mapsCount.Miramar = mapsCount.Miramar + 1;
                    break;
                  case "DihorOtok_Main":
                      mapsCount.Vikendi = mapsCount.Vikendi + 1;
                    break;
                  case "Erangel_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Heaven_Main":
                      mapsCount.Haven = mapsCount.Haven + 1;
                    break;
                  case "Kiki_Main":
                      mapsCount.Deston = mapsCount.Deston + 1;
                    break;
                  case "Range_Main":
                      mapsCount.Jackal = mapsCount.Jackal + 1;
                    break;
                  case "Savage_Main":
                      mapsCount.Sanhok = mapsCount.Sanhok + 1;
                    break;
                  case "Summerland_Main":
                      mapsCount.Karakin = mapsCount.Karakin + 1;
                    break;
                  case "Tiger_Main":
                    mapsCount.Taego = mapsCount.Taego + 1;
                    break;
                  case "Neon_Main":
                    mapsCount.Rondo = mapsCount.Rondo + 1;
                    break;
                  default:
                    break;
                }
          }

          
          let maxValue = Math.max(...Object.values(mapsCount));
          let mostPlayedMap = Object.keys(mapsCount).find(key => mapsCount[key] === maxValue);

          return(
            [mostPlayedMap, maxValue]
            );
      }
  }

  const scrollToAllTimeStats = () => {
    const element = document.getElementById("AllTimeStats");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  



    
    return (

        <>  
            {(fetchApi && fetchApi !== undefined) ?
              <>
              <div className='fulled d-flex flex-column justify-content-sm-between justify-content-start align-items-baseline'>
                <div>
                  <TitreH1 className="title-matches mb-0 text-uppercase">
                  {matchesNbToDisplay < matchesNbToCount ?
                    <span>{matchesNbToDisplay} </span>
                    :
                    <span>{matchesNbToCount} </span>
                  }
                  Last games 
                  </TitreH1>
                  
                  {props.refreshTimeDiff > 0 && 
                    <small className='text-white-50'>{`${props.refreshTimeDiff} minutes before new matches datas`}</small>
                  }
                  {props.refreshTimeDiff === 0 &&
                    <>
                      <small>You can now <button className="btn btn-link p-0 btn-link-refresh" onClick={props.refreshPageHandler}><small>refresh</small></button> your page</small>
                    </>
                  } 
    
                </div>
                <p className='d-flex align-items-baseline gap-1'>
                  <button className="btn btn-link p-0" onClick={scrollToAllTimeStats}>
                    <small>[see stats]</small>
                  </button>
                  {matchesNbToDisplay < matchesNbToCount &&
                    <button className='btn btn-link p-0' onClick={() => setMatchesNbToDisplay(matchesNbToDisplay+12)}><small>[see more games]</small></button>
                  }
                </p>
                
                
              </div>

              {TopProfile && 
                        <>
                            <div className='mainProfile fulled mb-1 container ms-0 ps-0 pe-0'>
                              <div className='col-12 d-flex gap-2 flex-column flex-lg-row align-items-end'>
                                  <div className={`w-100 mainPro py-0 px-3 px-md-5 rounded-0 btn btn-outline-${TopProfile[0][0].alertype} disabled opacity-100`}>
                                      <Profile 
                                          profile={TopProfile[0][0].name} 
                                          statName={TopProfile[0][0].title} 
                                          statValue={TopProfile[0][0].baseVal} 
                                          type={`w-100 py-2 py-md-4`}>
                                          {TopProfile[0][0].desc}
                                      </Profile>
                                  </div>
                                  <div className='closetobe d-flex justify-content-between flex-wrap mx-auto col-12 col-lg-3 gap-1'>
                                    <TitreH5 className='w-100 text-center mt-0 mb-0 fs-6'>This profile is close to be</TitreH5>
                                    {TopProfile[0][1] && 
                                    <Profile 
                                        profile={TopProfile[0][1].name} 
                                        statName={TopProfile[0][1].title} 
                                        // statValue={TopProfile[0][1].baseVal} 
                                        type={`btn btn-outline-${TopProfile[0][1].alertype} w-100 disabled rounded-0 overflow-hidden mx-auto mx-md-0 p-1`}>
                                    </Profile>}
                                    {TopProfile[0][2] && 
                                    <Profile 
                                        profile={TopProfile[0][2].name} 
                                        statName={TopProfile[0][2].title} 
                                        //statValue={TopProfile[0][2].baseVal} 
                                        type={`btn btn-outline-${TopProfile[0][2].alertype} w-100 disabled rounded-0 overflow-hidden mx-auto mx-md-0 p-1`}>
                                    </Profile>}
                                </div>
                              </div>
                            </div>
                        </>
                    }
              {
                fetchApi.slice(0,matchesNbToDisplay).map(match => {
                      return (
                          <Match addProfile={addProfile} nom={props.nom} matchInfos={match} refresh={props.refresh} key={match.data.id} idMatch={match.data.id} idPlayer={props.playerId} />
                      );
                  })
              }
              </>
              :
              <>
                {(matchesNbToCount && matchesNbToCount > 0) && 
                  <Loading>Loading Games</Loading>
                }
              </>
            }
        </>

    );
}

export default Matches;