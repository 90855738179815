import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import ApiGet from '../../../Hooks/ApiGet';
import TitreH5 from '../../Titres/TitreH5';
import Profiles from "../../Profiles/Profiles";
import Profile from "../../Profiles/Profile/ProfileSmall";
import moment from 'moment';

// Images maps
import erangel from '../../../assets/images/maps/erangel.jpg';
import karakin from '../../../assets/images/maps/karakin.jpg';
import miramar from '../../../assets/images/maps/miramar.jpg';
import paramo from '../../../assets/images/maps/paramo.jpg';
import sanhok from '../../../assets/images/maps/sanhok.jpg';
import jackal from '../../../assets/images/maps/jackal.jpg';
import vikendi from '../../../assets/images/maps/vikendi.jpg';
import taego from '../../../assets/images/maps/taego.jpg';
import deston from '../../../assets/images/maps/deston.jpg';
import missing from '../../../assets/images/maps/missing.png';
import rondo from '../../../assets/images/maps/rondo.jpg';

import Causer from '../../../Constants/DamageCauserName';
import DamageCauserIcon from '../../../Constants/DamageCauserIcon';

function Match(props) {
  const causerList = Causer;
  const match = props.matchInfos;
  const [matchTelemetry, setMatchTelemetry] = ApiGet();
  const [playerStats, setPlayerStats] = useState();
  const [playerTelemetry, setPlayerTelemetry] = useState();
  const [playerKilledby, setPlayerKilledby] = useState();
  const [StatsNotes, setStatsNotes] = useState([]);
  const [FinalProfiles, setFinalProfiles] = useState();


useEffect(() => {
  if(match) {
    let lienTelemetry = "";

    lienTelemetry = TelemetryLinkHandler();
    setMatchTelemetry(`${lienTelemetry}`, true);
    
    if(statsFromPlayer() && statsFromPlayer() !== undefined) {
      setPlayerStats(statsFromPlayer());
    }
  }
  // console.log('Match state update')

}, [])

    useEffect(() => {
      if(matchTelemetry && matchTelemetry !== undefined) {
        if(!playerTelemetry) {
           setPlayerTelemetry(checkPlayerTelemetryDatas())
        }
        if(!playerKilledby) {
          setPlayerKilledby(checkPlayerKilledby());
        }

         //if(match.data.id === "1937c45a-5b2b-402b-b692-6632aeaaa4ff")
           //console.log('idmatch : ' + match.data.id + ' -> ', matchTelemetry)
      }
        
    }, [matchTelemetry])

    useEffect(() => {
      if(playerStats) {
        // console.log(playerStats)
        // console.log(playerStats.heals)
        if(playerStats.winPlace === 1 && playerStats.kills > 10) {
          giveStatNote('legend', 'Did a top 1 and killed', playerStats.kills, [11]);
        } else {
          giveStatNote('killsdone', 'Kills', playerStats.kills, [4, 4.5, 5, 6, 7, 8]);
          giveStatNote('walk per game', 'Walk distance', playerStats.walkDistance, [2500, 3000,  3500, 4000, 4500, 5000, 6000, 7000, 8000]);
          giveStatNote('swim per game', 'Swim distance', playerStats.swimDistance, [200, 300, 400, 500, 600]);
          giveStatNote('early death', 'Survived %', Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration), [0,3,5,6,7,8,9,10,15, 20, 25, 30, 35, 40, 45, 50, 60]);
          giveStatNote('assistsdone', 'Assists', playerStats.assists, [2, 3, 4, 5, 6]);
          giveStatNote('roadkills', 'Road kills', playerStats.roadKills, [1,2,3]);
          giveStatNote('vehicleDestroys', 'Destroyed vehicles', playerStats.vehicleDestroys, [1,2,3]);
          giveStatNote('tk % / game', 'Team Kills', playerStats.teamKills, [1,2,3]);
          giveStatNote('weaponsAcquired', 'Weapons collected', playerStats.weaponsAcquired, [15,17,19,21,23,25]);
          giveStatNote('Head Hunter', 'Headshots', playerStats.headshotKills, [2,3,4,5,6]);
          giveStatNote('longestKill', 'Longest Kill', playerStats.longestKill, [350,500,600,700]);
          giveStatNote('Survivor', 'Heals used', playerStats.heals, [12,14,16,18,20,30,40]);

          if(playerStats.kills > 0 && playerStats.damageDealt > 0) {
            giveStatNote('dbnos', 'DBNOs / kill', (playerStats.DBNOs / playerStats.kills), [2,3,4]);
            giveStatNote('damagesPerKill', 'Damages / kill', (Math.round(playerStats.damageDealt) / playerStats.kills), [170, 200, 250, 300, 350, 400, 500, 600]);
          } else if (playerStats.kills === 0 && playerStats.damageDealt > 160) {
            giveStatNote('damagesPerKill', 'Damages', (Math.round(playerStats.damageDealt)), [170, 200, 250, 300, 350, 400, 500, 600]);
          } 
          if(playerStats.DBNOs > 0 && playerStats.kills === 0) {
            giveStatNote('dbnos', 'DBNOs', playerStats.DBNOs, [2,3,4]);
          }
          if ((playerStats.damageDealt * 100 / (playerStats.kills *100)) < 66) {
            giveStatNote('killsPardamages', 'Kills % / damages', playerStats.damageDealt * 100 / (playerStats.kills *100), [0, 10, 20, 30, 40, 50, 60])
          }
        }

        if (playerStats.kills < 4 || playerStats.revives > 2) {
          giveStatNote('Revives % par game', 'Revives', playerStats.revives, [2,3,4,5]);
        }
        
        // S'il n'y a que le profile par defaut 
        if (checkProfilesStats() != undefined && checkProfilesStats().length === 1) {
          
          // On défini un status si on a moins de 25% de la game et que la personne a tué des gens
          if(playerStats.kills == 1 && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) > 50)) {
            giveStatNote('Contributor', 'Kill', playerStats.kills, [1, 2, 3]);
          } else if((playerStats.kills > 0 && playerStats.kills < 4) && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) > 25)  && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) < 51)) {
            giveStatNote('Contributor', 'Kills', playerStats.kills, [1, 2, 3]);
          } else if((playerStats.kills > 0 && playerStats.kills < 4) && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) < 25)) {
            giveStatNote('killsdoneLow', 'Kills', playerStats.kills, [1, 2, 3]);
          } else if((playerStats.kills > 0 && playerStats.kills < 4) && (Math.round((playerStats.timeSurvived * 100) / match.data.attributes.duration) > 50)) {
            giveStatNote('killsdoneLowHigh', 'You did well in that game', playerStats.kills, [2, 3]);
          } else {
            giveStatNote('drive per game', 'Drive distance', playerStats.rideDistance, [3000, 4500, 6000, 8000, 9000, 10000, 11000]);
            giveStatNote('boosts', 'Boosts used', playerStats.boosts, [10,11,12,13,15]);
          }
        }


        props.addProfile(checkProfilesStats(), playerStats);
        setFinalProfiles(checkProfilesStats())
      }
    }, [playerStats])

    // useEffect(() => {
    //   if(FinalProfiles) {
    //     props.addToTopProfile(FinalProfiles[0])
    //   }
    // }, [FinalProfiles])


    function checkPlayerTelemetryDatas() {
      let allTelem = matchTelemetry;
      let playerTelem = [];

      for (let i = 0; i < allTelem.length; i++) {
        if(allTelem[i].character && allTelem[i].character.accountId === props.idPlayer) {
           playerTelem.push(allTelem[i])
        }   
        if((allTelem[i].victim && allTelem[i].victim.accountId === props.idPlayer) && allTelem[i].attacker) {
          playerTelem.push(allTelem[i]);
        }
      }



      return playerTelem;
    }

    function checkPlayerKilledby() {
      let allTelem = matchTelemetry;
      let playerKilledBy = null;
      let damageCauserIcon = DamageCauserIcon.None;

      for (let i = 0; i < allTelem.length; i++) {
        if(allTelem[i].victim && allTelem[i].victim.accountId === props.idPlayer) {

          // On check le tueur
          if(allTelem[i].killer && (allTelem[i].killer.accountId && allTelem[i].killer.name)) {
            let weaponKill = false;
            if(allTelem[i].killerDamageInfo.damageCauserName) {
              for (let ci = 0; ci < Object.values(DamageCauserIcon).length; ci++) {
                if(Object.keys(DamageCauserIcon)[ci] === allTelem[i].killerDamageInfo.damageCauserName) {
                  damageCauserIcon = Object.values(DamageCauserIcon)[ci]   
                }        
              }

              for (let j = 0; j < Object.values(causerList).length; j++) {
                if(Object.keys(causerList)[j] === allTelem[i].killerDamageInfo.damageCauserName) {
                  weaponKill = Object.values(causerList)[j]
                }    
              }
            }            
            
            // Si le tueur est un bot
            if(allTelem[i].killer.accountId.includes('ai')) {
              playerKilledBy = {
                killerId: false,
                killerName: "a Bot",
                weapon : weaponKill,
                icon : damageCauserIcon
              };
            } else {
              playerKilledBy = {
                killerId: allTelem[i].killer.accountId,
                killerName: allTelem[i].killer.name,
                weapon : weaponKill,
                icon : damageCauserIcon
              };
            }
  
          } else if(allTelem[i].killer === null) { // Ou la cause de la mort si pas tué par un joueur
            let causerName = "";

            if(allTelem[i].finishDamageInfo.damageCauserName) {
              for (let ci = 0; ci < Object.values(DamageCauserIcon).length; ci++) {
                if(Object.keys(DamageCauserIcon)[ci] === allTelem[i].finishDamageInfo.damageCauserName) {
                  damageCauserIcon = Object.values(DamageCauserIcon)[ci]   
                }             
              }
            }  

            for (let j = 0; j < Object.values(causerList).length; j++) {
              if(Object.keys(causerList)[j] === allTelem[i].finishDamageInfo.damageCauserName) {
                causerName = Object.values(causerList)[j]
              }    
            }

            if(allTelem[i].teamKillers_AccountId && allTelem[i].dBNOMaker) {
              playerKilledBy = {
                killerId: allTelem[i].teamKillers_AccountId,
                killerName: allTelem[i].dBNOMaker.name,
                weapon : "teamkill",
                icon : DamageCauserIcon.None
              };
            } else {
              playerKilledBy = {
                killerId: false,
                killerName: causerName,
                weapon : false,
                icon : damageCauserIcon
              };
            }

          }
          
        }   
      }

      return playerKilledBy;
    }

        function countAIs() {
          let countNbAIs = 0;
          
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {
              if(match.included[matchParticipant].attributes.stats && match.included[matchParticipant].attributes.stats !== undefined) {
                if(match.included[matchParticipant].attributes.stats.playerId && match.included[matchParticipant].attributes.stats.playerId !== undefined) {
                  if(match.included[matchParticipant].attributes.stats.playerId.includes('ai.')) {
                    countNbAIs = countNbAIs + 1;
                  }
                }
              } 

            });
          }
          return countNbAIs;
        }

        function countPlayerz() {
          let countNbPlayerz = 0;
          
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {
              if(match.included[matchParticipant].type && match.included[matchParticipant].type !== undefined) {
                  if(match.included[matchParticipant].type.includes('participant')) {
                    countNbPlayerz = countNbPlayerz + 1;
                  }
              } 

            });
          }
          return countNbPlayerz;
        }

        function convertDate(date) {
          const dateObject = new Date(date);
          const now = new Date();
          const diffMs = now - dateObject;
          const diffMins = Math.floor(diffMs / 60000);
          const diffHrs = Math.floor(diffMs / 3600000);
          let hoursDisplay = "hours";
          let minsDisplay = "minutes";
        
          if (diffHrs === 1) hoursDisplay = "hour";
          if (diffMins === 1) minsDisplay = "minute";
          
          if (diffMins < 60) {
            return `${diffMins} ${minsDisplay} ago`;
          } else if (diffHrs < 5) {
            return `${diffHrs} ${hoursDisplay} ago`;
          } else {
            return moment(date).format('DD/MM/YYYY - HH:mm');
          }
        }
        
        
        

        function statsFromPlayer() {
          let arrayStats = null;
          if(match && match !== undefined) {
            Object.keys(match.included).forEach(matchParticipant => {

              if(match.included[matchParticipant].type && match.included[matchParticipant].type !== undefined) {
                if(match.included[matchParticipant].type.includes('participant')) {

                  if(match.included[matchParticipant].attributes.stats.playerId.includes(props.idPlayer)) {
                    arrayStats = match.included[matchParticipant].attributes.stats;
                  }

                }
              } 
            });
          }
          return arrayStats;
        }

        function TelemetryLinkHandler() {
          let TelemetryLink = "";

          if(match && match !== undefined) {
            for (let inc = 0; inc < match.included.length; inc++) {
              if(match.included[inc].type === "asset") {
                TelemetryLink = match.included[inc].attributes.URL;
              }
            }

            return TelemetryLink;
          }
        }

       
        function giveStatNote(stat,title,statVal,levels) {
          let highestNote = 0;
          let arrayInverse = ['killsPardamages', 'early death']
          let statObject = {
            name: stat,
            title: title,
            baseVal: statVal,
            highestNote: highestNote,
            diff : null,
            diffType: null
          };
          const newNotes = StatsNotes;
          let noteExist = false;
          if(statVal >= levels[0]) {
            if(statVal && !isNaN(statVal)) {
              // On boucle dans les valeurs pour définir son niveau
              for (let i = 0; i <= levels.length; i++) {
                  if(statVal >= levels[i]) {
                      highestNote = (i + 1);
                  }
                }  
              
              if(newNotes.length > 0) {
                  // On check sur la stat existe déjà dans le tableau de Notes pour la remplacer
                  for (let i = 0; i < newNotes.length; i++) {
                      if(stat === newNotes[i].name) {
                          newNotes[i].highestNote = highestNote;
                          noteExist = true;
                          if(arrayInverse.includes(stat)) {
                            newNotes[i].diff = (0 + highestNote);
                          } else {
                            newNotes[i].diff = (levels.length - highestNote);
                          }

                          newNotes[i].diffType = "Top";
                          
                      }
                  }
              }
              
              if(noteExist === false) {
                  statObject.highestNote = highestNote;
                  if(arrayInverse.includes(stat)) {
                    statObject.diff = (0 + highestNote);
                  } else {
                    statObject.diff = (levels.length - highestNote);
                  }
                  
                  statObject.diffType = "Top";
  
                  newNotes.push(statObject)
              }
  
              newNotes.sort((a, b) => a.diff - b.diff);
              
              //console.log(match.data.id,'Notes',newNotes)
              setStatsNotes(newNotes);
          }
        
        }
    }

    function checkProfilesStats() {
      if(StatsNotes) {
          const nbProfiles = 4;
          const stats = StatsNotes;
          const profiles = Profiles;
          
          let resultProfiles = stats.slice(0,nbProfiles).map(objStats => {
              let objProfiles = profiles.find(obj => (obj.stat === objStats.name) && (obj.type === objStats.diffType));
              return {...objStats, ...objProfiles};
            });
            
            for (let i = 0; i < resultProfiles.length; i++) {
                if (resultProfiles[i].desc != undefined){
                  resultProfiles[i].desc = resultProfiles[i].desc.replace('PLAYERNAME', props.nom)
                }
                if ((resultProfiles[i].id == 20 && resultProfiles[i].diffType == "Top") && (resultProfiles[i].baseVal > 25 || playerStats.winPlace < 10)) {
                  resultProfiles[i].name = "Pacifist"
                  resultProfiles[i].short = "Pacifist"
                  resultProfiles[i].alertype = "redlight"
                }
            }
            
            return(resultProfiles);
      }
  }

    return (

        <>  
        {/*console.log(playerStats)*/}
          {(match && match !== undefined && playerStats && playerStats !== undefined) &&
            <div className={`mb-0 d-flex singleMatchSmall`} datatype-id={match.data.id}>
                <div className='imgContainer position-relative'>
                  <div className={`${(playerStats.winPlace === 1) && 'placetop1'} gameType d-flex flex-column gap-1 align-items-start h-100 w-100 justify-content-between pb-0`}>
                    {(FinalProfiles && FinalProfiles[0]) && 
                      <>
                        {(FinalProfiles.length === 1 && FinalProfiles[0].name === "Respawn" && (FinalProfiles[0].baseVal > 25 || playerStats.winPlace < 10)) ?
                          <Profile 
                              profile="Pacifist"
                              top={playerStats.winPlace}
                              statName="You did nothing. Really."
                              statValue="aaa"
                              type={`bg-primary bg-opacity-50 overlay backdrop w-100 disabled overflow-hidden mt-0 mx-auto mx-md-0 p-1 d-flex flex-column gap-0`}>
                              {/*FinalProfiles[0].desc*/}
                          </Profile>
                        :
                          <Profile 
                              profile={FinalProfiles[0].short}
                              top={playerStats.winPlace}
                              statName={FinalProfiles[0].title} 
                              statValue={FinalProfiles[0].stat === "killsdoneLowHigh" ? 'aaa' : FinalProfiles[0].baseVal} 
                              type={`bg-${FinalProfiles[0].alertype} bg-opacity-50 overlay backdrop w-100 disabled overflow-hidden mt-0 mx-auto mx-md-0 p-1 d-flex flex-column gap-0`}>
                              {/*FinalProfiles[0].desc*/}
                          </Profile>
                        }
                      </>
                    }
                  </div>
                </div>
                
              
            </div>
          }
        </>

    );
}

export default Match;