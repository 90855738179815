import { useEffect, useState } from 'react';
import MatchSmall from './MatchSmall';
import '../../../assets/css/SingleMatch.css';
import axios from 'axios';
import Key from '../../../Constants/Consts';

function Matches(props) {
    const matches = props.matchesId;
    const [fetchApi, setfetchApi] = useState();
    const PUBG_API_KEY = Key();
    const matchesNbToCount = 15;
    const [matchesNbToDisplay, setMatchesNbToDisplay] = useState(12);
    const [matchesProfile, setMatchesProfile] = useState([]);
    const [TopProfile, setTopProfile] = useState();

    useEffect(() => {
        if(matches) {       
          setfetchApi('')
          axios.post('https://wpai-cors.patatedouce-studio.fr/api/matches/', 
            {
              data : matches,
              key : PUBG_API_KEY,
              nbmatches : matchesNbToCount,
              excluded : 'tdm'
            },
            {
              mode: 'no-cors'
            }
          )
        
          .then(reponse => {
            if(reponse.data) {
              // console.log(reponse.data)
              let filteredMatches = []
              
              reponse.data.filter(match => {
                // console.log(match.data.attributes.matchType)
                if(match.data.attributes.matchType != "airoyale") {
                  filteredMatches.push(match)
                }
              })

              setfetchApi(filteredMatches)
            }
          })
          .catch(error => {
            console.log(error)
          })

        }
    }, [props.refreshTime])

    useEffect(() => {
      if(fetchApi && fetchApi !== undefined) {
        // console.log('Reload matches')
        // console.log(fetchApi)
      }
    }, [fetchApi])

     useEffect(() => {
      if(matchesProfile && matchesProfile.length === matchesNbToDisplay) {
        checkTopProfile();
      }
    }, [matchesProfile])

    useEffect(() => {
      if(TopProfile && TopProfile.length > 0) {
        props.addTopProfile(TopProfile[0])
      }
    }, [TopProfile])

    function checkTopProfile() {
      let profileMap = {};
      let topProfiles = [];
    
      matchesProfile.forEach(match => {
        const profile = match[0]; // Récupère le premier profil de chaque match
    
        if(profile !== undefined) {
          // Si le profil est déjà dans profileMap
          if (profileMap[profile.name]) {
            profileMap[profile.name].count++;
          } else {
            // Ajoute le profil avec un compteur initial à 1
            profileMap[profile.name] = { ...profile, count: 1 };
          }
        }
      });
    
      // Convertir l'objet en tableau et trier les profils par fréquence
      topProfiles = Object.values(profileMap).sort((a, b) => b.count - a.count);
      
      //console.log(topProfiles)
      setTopProfile([topProfiles[0]]); 
    }
    

    const addProfile = (newProfile) => {
      setMatchesProfile((prevProfiles) => [...prevProfiles, newProfile]); // Ajoute le profil
    };

    function checkMostPlayedMap() {
      let mapsCount = {
          Vikendi : 0,
          Erangel : 0,
          Sanhok : 0,
          Miramar : 0,
          Deston : 0,
          Paramo : 0,
          Haven : 0,
          Karakin : 0,
          Taego : 0,
          Jackal : 0,
          Rondo : 0
      }


      if(fetchApi && fetchApi !== undefined) {
          for (let i = 0; i < fetchApi.length; i++) {
              switch (fetchApi[i].data.attributes.mapName) {
                  case "Baltic_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Chimera_Main":
                      mapsCount.Paramo = mapsCount.Paramo + 1;
                    break;
                  case "Desert_Main":
                      mapsCount.Miramar = mapsCount.Miramar + 1;
                    break;
                  case "DihorOtok_Main":
                      mapsCount.Vikendi = mapsCount.Vikendi + 1;
                    break;
                  case "Erangel_Main":
                      mapsCount.Erangel = mapsCount.Erangel + 1;
                    break;
                  case "Heaven_Main":
                      mapsCount.Haven = mapsCount.Haven + 1;
                    break;
                  case "Kiki_Main":
                      mapsCount.Deston = mapsCount.Deston + 1;
                    break;
                  case "Range_Main":
                      mapsCount.Jackal = mapsCount.Jackal + 1;
                    break;
                  case "Savage_Main":
                      mapsCount.Sanhok = mapsCount.Sanhok + 1;
                    break;
                  case "Summerland_Main":
                      mapsCount.Karakin = mapsCount.Karakin + 1;
                    break;
                  case "Tiger_Main":
                    mapsCount.Taego = mapsCount.Taego + 1;
                    break;
                  case "Neon_Main":
                    mapsCount.Rondo = mapsCount.Rondo + 1;
                    break;
                  default:
                    break;
                }
          }

          
          let maxValue = Math.max(...Object.values(mapsCount));
          let mostPlayedMap = Object.keys(mapsCount).find(key => mapsCount[key] === maxValue);

          return(
            [mostPlayedMap, maxValue]
            );
      }
  }



    
    return (

        <>  
            {(fetchApi && fetchApi !== undefined) ?
              <>
              {
                fetchApi.slice(0,matchesNbToDisplay).map(match => {
                      return (
                          <MatchSmall addProfile={addProfile} nom={props.nom} matchInfos={match} refreshTime={props.refreshTime} key={match.data.id} idMatch={match.data.id} idPlayer={props.playerId} />
                      );
                  })
              }
              </>
              :
              <>
                {(matchesNbToCount && matchesNbToCount > 0) && 
                  <small>Loading Games</small>
                }
              </>
            }
        </>

    );
}

export default Matches;